import { useEffect, useRef } from 'react';

const useScript = (src: string, windowProperty?: string): void => {
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    scriptRef.current = script;
    const insertScript = () => {
      const { head } = document;
      if (head && scriptRef.current) {
        head.appendChild(scriptRef.current);
      }
    };

    const removeScript = () => {
      if (scriptRef.current && scriptRef.current.parentNode) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };

    if (!windowProperty || (window && windowProperty && !(window as any)[windowProperty])) insertScript();

    return () => {
      removeScript();
    };
  }, [src, windowProperty]);
};

export default useScript;
