'use client';

import { TransformedGridData } from '@/ts';
import Logo from '@/components/molecules/Logos/Square';
import HeadingSix from '@/components/atoms/HeadingSix/HeadingSix';
import { DisplayGridData } from '@/helpers/agnosticTransformer/article/utilities/display-helpers';
import styles from './styles/Grid.module.scss';
import getColumns from './helpers/getColumns';

interface CustomCSSProperties extends React.CSSProperties {
  '--tiles-number'?: number;
}

const Grid: React.FC<TransformedGridData> = ({
  title,
  tiles,
  tilesNumber,
  publication,
  dataTmDataTrack,
  id,
  domain,
  imageUrl,
  imageCrops,
}) => (
  <section className={styles['grid-container']} data-tmdatatrack={dataTmDataTrack} data-tmdatatrack-articleid={id}>
    <header className={styles['grid-header']}>
      <Logo publication={publication} size="small" />
      <HeadingSix>{title}</HeadingSix>
    </header>
    <div
      className={styles['grid-wrapper']}
      style={{ '--tiles-number': getColumns(tilesNumber) } as CustomCSSProperties}
    >
      {DisplayGridData(title, tiles, imageCrops, imageUrl, domain, publication)}
    </div>
  </section>
);

export default Grid;
