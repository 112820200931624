import React, { PropsWithChildren } from 'react';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import styles from './styles/ArticleBody.module.scss';

interface ArticleBodyProps extends PropsWithChildren {
  dataTestId?: string;
  publication?: string;
}

const renderChildren = (children: React.ReactNode, publication: string | undefined) =>
  React.Children.map(
    children,
    (child: any) =>
      child &&
      React.cloneElement(child, {
        publication,
      })
  );

const ArticleBody: React.FC<ArticleBodyProps> = ({ children, dataTestId, publication }) => (
  <ErrorBoundaryFallback>
    <article id="article-body" className={styles.article} data-testid={dataTestId}>
      {renderChildren(children, publication)}
    </article>
  </ErrorBoundaryFallback>
);

export default ArticleBody;
