'use client';

import useConverse from '@/store/converse/converse';
import { useEffect, useRef } from 'react';
import getDirFileName from '@/helpers/utils/dirPath';
import { ConverseServiceProps } from '@/commercial/types';
import { isPublisherPurposesConsented } from '@/store/consent/selectors';
import useConsentDataService from '@/store/consent/consent';

const fileDirectory = getDirFileName(import.meta.url);

const CommercialConverseSerive: React.FC<{ converseServiceProps: ConverseServiceProps }> = ({
  converseServiceProps,
}: {
  converseServiceProps: ConverseServiceProps;
}) => {
  const dataFetched = useRef<boolean>(false);
  const consentStore = useConsentDataService();
  const hasPublisherConsent = isPublisherPurposesConsented()(consentStore);
  useEffect(() => {
    if (dataFetched.current || !hasPublisherConsent) {
      return;
    }
    const controller = new AbortController(); // Create an AbortController instance
    const { signal } = controller;
    let htmlTemplate: string = '';
    const fetchData = async () => {
      try {
        dataFetched.current = true;
        const urlSearchParams = new URLSearchParams({
          url: converseServiceProps.articleUrl,
          cre: `navigator-${converseServiceProps.platform}`,
          cip: '3456', // possible converse injection positions commercial slot 3, 4, 5, and 6
          view: 'component',
          npa: '0', // non-personalised ads is always 0 as it is applicable only for consented users.
        });
        const url = new URL(`${converseServiceProps.endpointURL}?${urlSearchParams}`);
        const response = await fetch(url.toString(), {
          credentials: 'include',
          signal,
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch converse data: ${response.statusText}`);
        }
        htmlTemplate = await response.text();
        useConverse.getState().actions.addConverseDataToStore({ htmlTemplate });
      } catch (error) {
        const errMsg =
          error instanceof Error && error.name !== 'AbortError'
            ? 'Error fetching converse data'
            : 'An unknown error occurred';
        console.error(`${fileDirectory} ${errMsg}:`, error);
      }
    };
    fetchData();
    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort();
    };
  }, [hasPublisherConsent]);
  return null;
};
export default CommercialConverseSerive;
