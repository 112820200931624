'use client';

import AppStoreSvg from '@/components/atoms/Icons/AppStore';
import GooglePlaySvg from '@/components/atoms/Icons/GooglePlay';
import CommentingSvg from '@/components/atoms/Icons/CommentingBox';
import CommentingSvgLarge from '@/components/atoms/Icons/CommentingBoxLarge';
import Logo from '@/components/molecules/Logos/Square';
import { publicationFont } from '@/helpers/utils/fonts';
import HeadingSix from '@/components/atoms/HeadingSix/HeadingSix';
import { useEffect, useState } from 'react';
import { CommentingBoxProps } from '@/ts';
import clientWidth from '@/helpers/utils/clientWidth';
import styles from './styles/CommentingBox.module.scss';

const CommentingBox: React.FC<CommentingBoxProps> = ({
  title,
  publication,
  content,
  appsUrls = { androidUrl: '', appleUrl: '' },
  dataTestId = 'comment-box',
}) => {
  const fontClass = publicationFont(publication);
  const [isClient, setIsClient] = useState(false);
  const screenWidth = clientWidth();

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  function renderContent() {
    if (screenWidth === 'small' || screenWidth === 'medium') {
      return (
        <div id="comment-box" className={styles['commenting-box-container']} data-test-id={dataTestId}>
          <div className={styles['title-wrapper']}>
            <Logo publication={publication} size="xsmall" />
            <HeadingSix className={fontClass}>{title}</HeadingSix>
          </div>
          <div className={styles['text-content-svg-image-wrapper']}>
            <div className={styles.content}>{content}</div>
            <div className={styles['svg-wrapper']}>
              <CommentingSvg />
            </div>
          </div>
          <div className={styles['app-links-wrapper']}>
            <a href={appsUrls.androidUrl} target="_blank" rel="noreferrer">
              <GooglePlaySvg />
            </a>
            <a href={appsUrls.appleUrl} target="_blank" rel="noreferrer">
              <AppStoreSvg />
            </a>
          </div>
        </div>
      );
    }
    if (screenWidth === 'large' || screenWidth === 'xlarge') {
      return (
        <div id="comment-box" className={styles['commenting-box-container-large-screen']} data-test-id={dataTestId}>
          <div className={styles['left-wrapper']}>
            <div className={styles['title-wrapper']}>
              <Logo publication={publication} size="xsmall" />
              <HeadingSix className={fontClass}>{title}</HeadingSix>
            </div>

            <div className={styles.content}>{content}</div>

            <div className={styles['app-links-wrapper']}>
              <a href={appsUrls.androidUrl} target="_blank" rel="noreferrer">
                <GooglePlaySvg />
              </a>
              <a href={appsUrls.appleUrl} target="_blank" rel="noreferrer">
                <AppStoreSvg />
              </a>
            </div>
          </div>
          <div className={styles['right-wrapper']}>
            <CommentingSvgLarge />
          </div>
        </div>
      );
    }
    return null;
  }

  return renderContent();
};

export default CommentingBox;
