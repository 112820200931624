import React, { useState, useEffect } from 'react';
import HeadingSix from '@/components/atoms/HeadingSix/HeadingSix';
import { QuestionnaireProps } from '@/ts/interfaces/standardQuiz';
import WrongIcon from '@/components/atoms/Icons/WrongIcon';
import CorrectIcon from '@/components/atoms/Icons/CorrectIcon';
import ImageEmbed from '@/components/molecules/ImageEmbed/ImageEmbed';
import QuizConstants from '../../../../constants/quiz';
import CrossCircle from '../../../atoms/Icons/CrossCircle';
import CheckCircle from '../../../atoms/Icons/CheckCircle';
import styles from './styles/Questionnaire.module.scss';

const buttonIconColor = `var(--color-sem-icon-neutral-default)`;

const Questionnaire: React.FC<QuestionnaireProps> = ({
  headingText,
  options,
  resultText,
  answerImage,
  questionImage,
  onOptionSelected,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [answered, setAnswered] = useState(false);
  const sizes = '(max-width: 458px) 458px, (max-width: 615px) 615px, 950px';

  const handleOptionClick = (index: number) => {
    if (answered) return;

    setSelectedOption(index);
    setAnswered(true);

    // Determine if the selected answer is correct
    const isCorrect = options[index].correctAnswer;
    onOptionSelected(isCorrect); // Notify the parent component with the correctness
  };

  const generateOptionsClassNames = ({ option, index }: { index: number; option: any }) => {
    const selected = selectedOption === index;
    const correctAnswer = option.correctAnswer && selectedOption !== null;
    const wrongAnswer = !option.correctAnswer && selected;
    const correctIndexOption = option.correctAnswer && option.text;
    return `${styles['option-default']}
    ${selectedOption !== null ? styles['option-is-selected'] : ''}
    ${correctIndexOption && selectedOption ? styles['option-correct-index'] : ''}
    ${correctAnswer ? styles['option-correct'] : ''}
    ${wrongAnswer ? styles['option-wrong'] : ''}
    ${selected ? styles['option-selected'] : ''}
    `;
  };

  useEffect(() => {
    setAnswered(false);
    setSelectedOption(null);
  }, [headingText]);

  return (
    <div className={styles.questionnaire}>
      <HeadingSix className={styles.question}>{headingText}</HeadingSix>
      {questionImage &&
        (() => (
          <ImageEmbed
            data={{
              src: questionImage.src,
              srcSet: questionImage.srcSet,
              alt: questionImage.altText,
              sizes,
              loading: 'lazy',
            }}
          />
        ))()}
      <div className={styles['button-wrapper']} data-testid="quiz-test-buttons-wrapper">
        {options?.map((option, index) => (
          <button
            key={index}
            className={generateOptionsClassNames({ option, index })}
            disabled={answered}
            onClick={() => handleOptionClick(index)}
          >
            <span>{option.text}</span>
            {selectedOption !== null && (
              <>
                {option.correctAnswer ? (
                  <CheckCircle color={buttonIconColor} />
                ) : (
                  <CrossCircle color={buttonIconColor} />
                )}
              </>
            )}
          </button>
        ))}
      </div>
      {answered && (
        <>
          <div className={styles['correct-answer-message']}>
            {options[selectedOption!]?.correctAnswer ? (
              <CorrectIcon color={buttonIconColor} />
            ) : (
              <WrongIcon color={buttonIconColor} />
            )}
            <span>
              {options[selectedOption!]?.correctAnswer ? QuizConstants.CORRECT : QuizConstants.WRONG} {resultText}
            </span>
          </div>
          {answerImage &&
            (() => (
              <ImageEmbed
                data={{
                  src: answerImage.src,
                  srcSet: answerImage.srcSet,
                  alt: answerImage.altText,
                  sizes,
                  loading: 'lazy',
                }}
              />
            ))()}
        </>
      )}
    </div>
  );
};

export default Questionnaire;
