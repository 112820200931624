const CONSTANTS = {
  PERMISSION: {
    DEFAULT: 'default',
    DENIED: 'denied',
    GRANTED: 'granted',
  },
  WEB_ALERT_STORAGE_NAME: 'webAlertPermission',
  WEB_ALERT_PROVIDER_STORAGE_NAME: 'webAlertProvider',
  TTL: {
    ACCEPT: 99999,
    DECLINE: 30,
  },
  ONE_SIGNAL: 'one-signal',
  AIRSHIP: 'airship',
};

export default CONSTANTS;
