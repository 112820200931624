'use client';

import { AffiliateContentProps } from '@/ts/interfaces/affiliateContent';
import Image from '@/components/atoms/Image/Image';
import AffiliateDisclaimer from '@/components/atoms/AffiliateDisclaimer/AffiliateDisclaimer';
import getFormattedPrice from '@/helpers/utils/priceFormatter';
import { getAffiliateContentHeaderFont } from '@/helpers/utils/fonts';
import styles from './styles/AffiliateContent.module.scss';
import AffiliateContentRatings from './AffiliateContentRatings';
import AffiliateContentSection from './AffiliateContentSection';

const getBtnTxt = (btnTxt: string | undefined, provider: string) => btnTxt || `Buy Now on ${provider}`;

const AffiliateContent: React.FC<AffiliateContentProps> = ({
  images = [],
  title,
  rating,
  was,
  price,
  provider,
  url,
  buttonText,
  shouldShowDisclaimer,
  dataTestId,
  children,
}) => {
  const image = images.length > 0 ? images[0] : null;
  return (
    <div data-testid={dataTestId} className={`${styles['affiliate-content-container']} affiliate-content`}>
      <div className={`${styles['content-title']} ${getAffiliateContentHeaderFont()}`}>{title}</div>
      {rating && <AffiliateContentRatings bestRating="5" rating={rating}></AffiliateContentRatings>}
      {shouldShowDisclaimer && <AffiliateDisclaimer showIcon={true} />}
      <AffiliateContentSection
        className={`${styles['content-details']}${image ? ` ${styles['has-content-img']}` : ''}`}
        displayHeading={false}
        isCollapsible={false}
      >
        {image?.src && (
          <div className={styles['content-image']}>
            <Image
              alt={image.altText || 'Content Image'}
              src={image.src}
              sizes={image.sizes}
              srcSet={image.srcSet}
              fetchPriority="auto"
            />
          </div>
        )}
        <div className={styles['content-price-details']}>
          {was && <div className={styles['content-was-price']}>{getFormattedPrice(was)}</div>}
          <div data-testid="content-price" className={styles['content-price']}>
            {getFormattedPrice(price)}
          </div>
          <div className={styles['content-provider']}>{provider}</div>
          <a
            className={`${styles['content-buy-btn']} buy-btn`}
            aria-label="Buy button"
            href={url}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {getBtnTxt(buttonText, provider)}
          </a>
        </div>
      </AffiliateContentSection>
      {children}
    </div>
  );
};
export default AffiliateContent;
