import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import styles from './styles/Modal.module.scss';

export interface ModalProps {
  outsideClickClose?: boolean;
  preventBodyScroll?: boolean;
  children?: ReactNode;
  onClose: () => void;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  onClose,
  outsideClickClose,
  preventBodyScroll,
  ...props
}) => {
  const outsideListner = (evt: MouseEvent) => {
    const modalElement = document.getElementById('modal');
    if (!modalElement?.contains(evt.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (preventBodyScroll) {
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    }
    if (outsideClickClose) {
      document.addEventListener('click', outsideListner);
    }
    return () => {
      if (outsideClickClose) {
        document.removeEventListener('click', outsideListner);
      }
      if (preventBodyScroll) {
        document.body.style.overflow = 'auto';
        document.body.style.height = 'auto';
      }
    };
  }, [preventBodyScroll, outsideClickClose]);

  return (
    <div id={styles.modal} {...props} data-testid="pop-up-modal">
      {props.children}
    </div>
  );
};

export default Modal;
