import React from 'react';

interface CameraIconProps {
  color?: string;
}

const CameraIcon: React.FC<CameraIconProps> = ({ color = 'var(--color-sem-icon-primary-default)' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_209_94274)">
      <path
        opacity="0.16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H2V10.6667C2 11.403 2.59695 12 3.33333 12H12.6667C13.403 12 14 11.403 14 10.6667V4ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        fill={color}
      />
      <path
        d="M2 3.33331H14V11.3333C14 12.0697 13.403 12.6666 12.6667 12.6666H3.33333C2.59695 12.6666 2 12.0697 2 11.3333V3.33331Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="8" r="2" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.3332 1.33331L12.6665 1.33331"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_209_94274">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CameraIcon;
