import { RecommenderFourBlockProps } from '@/ts';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import Recommended from '../Recommended/Recommended';

const FourBlocksRecommender: React.FC<RecommenderFourBlockProps> = ({
  title,
  indexStart,
  publication,
  rightHandRail,
  isMock,
  mockData,
  boxPosition,
  dataTestId,
  showBox = true,
}) => (
  <>
    <Recommended
      title={title}
      indexStart={indexStart}
      indexEnd={indexStart + 1}
      publication={publication}
      teaserType={'secondary'}
      rightHandRail={rightHandRail}
      dataTmDataTrack="rhs"
      dataTestId={dataTestId}
      isMock={isMock}
      mockData={mockData}
    />
    {showBox && <CommercialBox location={'aside'} position={boxPosition} />}
    <Recommended
      indexStart={indexStart + 1}
      indexEnd={indexStart + 4}
      publication={publication}
      teaserType={'secondary'}
      rightHandRail={rightHandRail}
      dataTmDataTrack="rhs"
      dataTestId={dataTestId}
      isMock={isMock}
      mockData={mockData}
    />
  </>
);

export default FourBlocksRecommender;
