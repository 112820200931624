import { FigureProps } from '@/ts/interfaces';
import Image from '../../atoms/Image/Image';
import styles from './styles/Figure.module.scss';

interface CustomCSSProperties extends React.CSSProperties {
  '--figure-aspect-ratio'?: number;
}

const Figure: React.FC<FigureProps> = ({
  data: { src, alt, aspect = 1.5, ...rest },
  dataTmDataTrack,
  dataTmDataTrackArticleId,
  children,
}) => (
  <figure
    className={styles.figure}
    data-tmdatatrack={dataTmDataTrack}
    data-tmdatatrack-articleid={dataTmDataTrackArticleId}
    style={{ '--figure-aspect-ratio': aspect } as CustomCSSProperties}
  >
    <Image src={src} alt={alt} {...rest} />
    {children}
  </figure>
);

export default Figure;
