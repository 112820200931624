import getDirFileName from '@/helpers/utils/dirPath';
import log from '@/helpers/utils/log';

export interface NewsletterSubscribePayload {
  email: string;
  accName: string;
  listName: string;
  SignUpSource: string;
  TrackingId: string;
  MailingListId: string;
  PublicationId: string;
  ConsentDate: string;
}

const subscribeToNewsletter = async (
  payload: NewsletterSubscribePayload,
  newsletterSubscribeBaseUrl: string
): Promise<any> => {
  const subscribeEndpoint = `${newsletterSubscribeBaseUrl}/subscriptions/open/${payload.MailingListId}`;
  const fileDirectory = getDirFileName(import.meta.url);
  try {
    const response = await fetch(subscribeEndpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data: any = await response.json();

    if (response.ok) {
      return { status: 'success', data, statusCode: response.status };
    }

    log().error({ message: `${fileDirectory} Error subscribing to newsletter:`, error: data });
    return {
      status: 'error',
      message: response.status === 422 ? 'Please enter a valid email' : 'Something went wrong',
      statusCode: response.status,
    };
  } catch (error: any) {
    log().error({ message: `${fileDirectory} Error subscribing to newsletter:`, error });
    return { status: 'error', message: error.message };
  }
};

export default subscribeToNewsletter;
