import { RecommenderProps } from '@/ts';
import Recommended from '../Recommended/Recommended';

const FiveBlocksRecommender: React.FC<RecommenderProps> = ({
  publication,
  indexStart,
  title,
  rightHandRail,
  teaserType,
  dataTmDataTrack,
  dataTestId,
  isMock,
  mockData,
}) => (
  <Recommended
    indexStart={indexStart}
    publication={publication}
    indexEnd={indexStart + 5}
    teaserType={teaserType}
    rightHandRail={rightHandRail}
    dataTmDataTrack={dataTmDataTrack}
    dataTestId={dataTestId}
    isMock={isMock}
    title={title}
    mockData={mockData}
  />
);

export default FiveBlocksRecommender;
