'use client';

import UnsupportedIcon from '@/components/atoms/Icons/Unsupported';
import { UnsupportedContentProps } from '@/ts';
import styles from './styles/UnsupportedContent.module.scss';

const circleBackground = `var(--color-sem-icon-neutral-default)`;

const UnsupportedContent: React.FC<UnsupportedContentProps> = ({ message }) => (
  <section className={styles.wrapper} tabIndex={0} aria-label={message} data-testid="unsupported-content">
    <UnsupportedIcon background={circleBackground} />
    <h6 className={styles.message}>{message}</h6>
  </section>
);

export default UnsupportedContent;
