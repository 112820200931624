const IMAGE = {
  sizeMappings: {
    imageEmbed: [
      { size: 's458b', width: 458 },
      { size: 's1200c', width: 1200 },
    ],
  },
};

export default IMAGE;
