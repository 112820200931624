'use client';

import { useEffect, useState, useRef } from 'react';
import CTA from '@/components/atoms/CTA/CTA';
import { Newsletter } from '@/ts';
import validateEmail from '@/helpers/utils/isValidEmail';
import { v4 as uuidv4 } from 'uuid';
import Logo from '@/components/molecules/Logos/Square';
import TextInput from '@/components/atoms/TextInput/TextInput';
import getDirFileName from '@/helpers/utils/dirPath';
import styles from './styles/NewsletterSignup.module.scss';
import subscribeToNewsletter from './subscribeToNewsletter';
import SignupConfirmation from './SignupConfirmation';

export interface NewsletterSignupProps extends React.PropsWithChildren {
  newsletterSubscribeBaseUrl: string;
  publication: string;
  newsletterAttributes?: Newsletter;
  articleID: string;
  consentDate: string;
}

const NewsletterSignup: React.FC<NewsletterSignupProps> = ({
  publication,
  newsletterSubscribeBaseUrl,
  newsletterAttributes: initialNewsletterAttributes,
  articleID,
  consentDate,
}) => {
  const [email, setEmail] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newsletterAttributes, setNewsletterAttributes] = useState<Newsletter | undefined>(initialNewsletterAttributes);
  const fileDirectory = getDirFileName(import.meta.url);

  const moreNewslettersLink = useRef('');
  const privacyPolicyLink = useRef('');

  useEffect(() => {
    const { protocol, hostname } = window.location;
    moreNewslettersLink.current = `${protocol}//${hostname}/newsletter-preference-centre/?utm_source=newslettersignupskinny`;
    privacyPolicyLink.current = `${protocol}//${hostname}/privacy-notice/`;

    if (initialNewsletterAttributes) return; // Skip fetching if attributes are already available

    const controller = new AbortController(); // Create an AbortController instance
    const { signal } = controller;

    const fetchArticleData = async () => {
      try {
        const response = await fetch(`/api/newsletterData?articleID=${articleID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch article data: ${response.statusText}`);
        }

        const data = await response.json();
        setNewsletterAttributes(data);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== 'AbortError') {
            console.error(`${fileDirectory} Error fetching article data:`, error);
          }
        } else {
          console.error(`${fileDirectory} An unknown error occurred:`, error);
        }
      }
    };

    fetchArticleData();

    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort();
    };
  }, [articleID, initialNewsletterAttributes]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    setErrorMessage(!submitAttempted || validateEmail(emailInput) ? '' : 'Please enter a valid email');
  };

  const handleSubmit = async () => {
    setSubmitAttempted(true);
    const isEmailValid = validateEmail(email);
    setErrorMessage(isEmailValid ? '' : 'Please enter a valid email');
    if (isEmailValid) {
      const response = await subscribeToNewsletter(
        {
          email,
          accName: publication,
          listName: newsletterAttributes?.title || '',
          SignUpSource: 'Skinny',
          TrackingId: uuidv4(),
          MailingListId: newsletterAttributes ? newsletterAttributes.mailingListId : '',
          PublicationId: publication,
          ConsentDate: consentDate, // This consent date fetching from Commander publication
        },
        newsletterSubscribeBaseUrl
      );
      if (response.status === 'success') {
        window.feliz?.event('newsletter-subscribe-success-click', {
          subscriptionSource: 'newsletter-inline',
          isRegistered: 'true',
        });
        setIsSubmitted(true);
      } else {
        console.error(`${fileDirectory} Error subscribing to newsletter: `, response.message);
        setErrorMessage(response.message || 'An error occurred while subscribing. Please try again.');
        window.feliz?.event('newsletter-subscribe-invalid-email-click', {
          subscriptionSource: 'newsletter-inline',
        });
      }
    } else {
      window.feliz?.event('newsletter-subscribe-invalid-email-click', {
        subscriptionSource: 'newsletter-inline',
      });
    }
  };

  if (!(newsletterAttributes && newsletterAttributes?.mailingListId)) {
    return null;
  }

  if (isSubmitted) {
    return (
      <SignupConfirmation
        moreNewslettersLink={moreNewslettersLink.current}
        privacyPolicyLink={privacyPolicyLink.current}
      />
    );
  }

  return (
    <div
      className={styles.container}
      data-testid="newsletter-signup-inline"
      data-tmdatatrack="inline-engagement-ncu"
      data-tmdatatrack-subtype="newsletter"
    >
      <div className={styles['header-container']}>
        <div className={styles.logo}>
          <Logo publication={publication} size="xsmall" />
        </div>
        <span className={styles.title} data-testid="NewsletterSignup_Title">
          {newsletterAttributes?.callToAction && newsletterAttributes.callToAction !== ''
            ? newsletterAttributes.callToAction
            : `Sign up to FREE email alerts from ${newsletterAttributes?.displayName || publication}`}
        </span>
      </div>
      <div className={styles['input-container']}>
        <TextInput
          className={styles['newsletter-email-input']}
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your Email..."
          isValid={!errorMessage}
          errorMessage={submitAttempted && errorMessage ? errorMessage : undefined}
        />
        <CTA
          dataTmDataTrack="newsletter-inline"
          design="primary"
          disabled={!!errorMessage}
          onClick={handleSubmit}
          label={newsletterAttributes?.signupButtonText || 'Subscribe'}
        />
      </div>
      <p className={styles.disclaimer}>
        We use your sign-up to provide content in ways you’ve consented to and improve our understanding of you. This
        may include adverts from us and third parties based on our knowledge of you.{' '}
        <a href={privacyPolicyLink.current || '/privacy-notice'}>More info</a>
      </p>
    </div>
  );
};

export default NewsletterSignup;
