'use client';

/* eslint-disable @next/next/no-img-element */

import { publicationFont } from '@/helpers/utils/fonts';
import useScript from '@/hooks/useScript';
import { useCallback } from 'react';
import CenteredOverlay from '@/components/atoms/CenteredOverlay/CenteredOverlay';
import CTA from '@/components/atoms/CTA/CTA';
import { YT_IFRAME_API } from '@/constants';

import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import styles from './styles/YoutubeEmbed.module.scss';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback/ErrorBoundaryFallback';

export interface YoutubeEmbedProps {
  caption: string;
  placeholder?: string;
  publication: string;
  related?: boolean;
  videoId: string;
  dataTestId?: string;
}

const PlayButton: React.FC<any> = ({ clickHandler, ...props }) => (
  <CTA
    design={'black'}
    hideLabel={true}
    onClick={clickHandler}
    ariaLabel={props['aria-label']}
    icons={{
      icon: 'play',
    }}
  />
);

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({
  caption,
  videoId,
  placeholder,
  publication,
  related = false,
  dataTestId = 'youtube-embed',
}) => {
  useScript(YT_IFRAME_API, 'YT');
  const image = placeholder || `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  const clickHandler = useCallback(() => {
    const playerConfig = {
      height: '100%',
      width: '100%',
      videoId,
      playerVars: {
        autoplay: 1,
        playsinline: 1,
        player: 'white',
        controls: 1,
        origin: window.location.origin,
        rel: related ? 1 : 0,
        widget_referrer: window.location.href,
      },
      events: {
        // onReady: onready,
        onStateChange: (event: any) =>
          event.data === 0 &&
          clickEvent('YOUTUBE_VIDEO:COMPLETED', {
            componentTitle: caption,
            componentID: videoId,
            componentText: '',
            linkURL: window.location.href,
            ...analytics,
          }),
      },
      // adsConfig: {},
    };
    clickEvent('YOUTUBE_VIDEO:PLAY', {
      componentTitle: caption,
      componentID: videoId,
      componentText: '',
      linkURL: window.location.href,
      ...analytics,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const youtubePlayer = new window.YT.Player(`player-${videoId}`, playerConfig);
  }, [videoId, caption, related, clickEvent, analytics]);

  return (
    <ErrorBoundaryFallback>
      <div
        className={styles['video-wrapper']}
        data-tmdatatrack="video"
        data-tmdatatrack-name="youtube"
        data-testid={dataTestId}
      >
        <div className={styles['aspect-ratio-wrapper']}>
          <div className={styles['mounting-element']} id={`player-${videoId}`}>
            <img
              className={styles['image-placeholder']}
              src={image}
              srcSet={
                placeholder ||
                `https://img.youtube.com/vi/${videoId}/mqdefault.jpg 320w, https://img.youtube.com/vi/${videoId}/hqdefault.jpg 480w, https://img.youtube.com/vi/${videoId}/maxresdefault.jpg 1280w`
              }
              sizes="100vw"
              alt={caption}
              loading="lazy"
            />
            <CenteredOverlay>
              <div className={styles['play-button-div']}>
                <PlayButton clickHandler={clickHandler} aria-label={`Play video ${caption}`} />
              </div>
            </CenteredOverlay>
          </div>
        </div>
        <div className={[styles['video-caption'], publicationFont(publication)].join(' ')}>{caption}</div>
      </div>
    </ErrorBoundaryFallback>
  );
};

export default YoutubeEmbed;
