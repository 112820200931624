'use client';

import { KnowledgeRecommenderProps } from '@/ts/interfaces/knowledgeRecommender';
import { getRhsData } from '@/helpers/agnosticTransformer/article/utilities';
import { publicationFont } from '@/helpers/utils/fonts';
import useFeliz from '@/store/feliz/feliz';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import Teaser from '../Teaser';
import styles from './style/KnowledgeRecommender.module.scss';

const KnowledgeRecommender: React.FC<KnowledgeRecommenderProps> = ({
  sectionTitle = 'Read Next',
  publication,
  dataTestId = 'knowledge-recommender',
  showTag = true,
}) => {
  const fontClass = publicationFont(publication);
  const felizStore = useFeliz();
  const readNextItems = felizStore.state.client.recirculation?.inline?.readNext;

  return (
    <ErrorBoundaryFallback>
      {readNextItems && readNextItems.length > 0 ? (
        <>
          <div className={styles['knowledge-recommender-header']}>
            <h6 className={fontClass}>{sectionTitle}</h6>
          </div>
          <div className={styles['teaser-list']} data-testid={dataTestId}>
            {readNextItems.map((item, i) => {
              const data = { ...item, crop: { small: 's458', medium: 's615', large: 's1023' } };
              const transformedData = getRhsData(data);
              const teaserItem = { ...transformedData };
              return (
                <Teaser
                  publication={publication}
                  key={i}
                  data={teaserItem}
                  teaserType="primary"
                  teaserSize="small"
                  index={i}
                  dataTmdatatrack="read-next"
                  as="span"
                  showTag={showTag}
                />
              );
            })}
          </div>
        </>
      ) : null}
    </ErrorBoundaryFallback>
  );
};

export default KnowledgeRecommender;
