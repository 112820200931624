'use client';

import { useEffect, useState } from 'react';
import { RHRecommenderProps } from '@/ts';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import FourBlocksRecommender from '../RecommenderBlocks/FourBlocksRecommender';
import FiveBlocksRecommender from '../RecommenderBlocks/FiveBlocksRecommender';

const RHRecommender: React.FC<RHRecommenderProps> = ({ publication, rightHandRail, showCommercialBox }) => {
  const [shouldRender, setShouldRender] = useState<boolean | null>(null);
  const breakPoint = 1024;
  useEffect(() => {
    setShouldRender(window.innerWidth >= breakPoint);
    const handleResize = () => setShouldRender(window.innerWidth >= breakPoint);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (shouldRender === null) return null; // Don't render until we know if we should
  return (
    <>
      {shouldRender && (
        <>
          <FourBlocksRecommender
            title="RECOMMENDED"
            indexStart={0}
            publication={publication}
            teaserType={'secondary'}
            rightHandRail={rightHandRail}
            boxPosition={2}
            dataTmDataTrack="rhs"
            dataTestId="recommended-section"
            showBox={showCommercialBox}
          />
          {showCommercialBox && <CommercialBox location={'aside'} position={3} />}
          <FourBlocksRecommender
            indexStart={4}
            publication={publication}
            teaserType={'secondary'}
            rightHandRail={rightHandRail}
            boxPosition={4}
            dataTmDataTrack="rhs"
            dataTestId="recommended-section"
            showBox={showCommercialBox}
          />
          {showCommercialBox && <CommercialBox location={'aside'} position={5} />}
          <FourBlocksRecommender
            indexStart={8}
            publication={publication}
            teaserType={'secondary'}
            rightHandRail={rightHandRail}
            boxPosition={6}
            dataTmDataTrack="rhs"
            dataTestId="recommended-section"
            showBox={showCommercialBox}
          />
          {showCommercialBox && <CommercialBox location={'aside'} position={7} />}
          <FiveBlocksRecommender
            indexStart={12}
            publication={publication}
            teaserType={'secondary'}
            rightHandRail={rightHandRail}
            dataTmDataTrack="rhs"
            dataTestId="recommended-section"
            showBox={showCommercialBox}
          />
          {showCommercialBox && <CommercialBox location={'aside'} position={8} />}
          <FiveBlocksRecommender
            indexStart={17}
            publication={publication}
            teaserType={'secondary'}
            rightHandRail={rightHandRail}
            dataTmDataTrack="rhs"
            dataTestId="recommended-section"
            showBox={showCommercialBox}
          />
        </>
      )}
    </>
  );
};
export default RHRecommender;
