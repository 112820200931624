import React from 'react';
import CTA from '@/components/atoms/CTA/CTA';
import styles from './styles/NewsletterSignup.module.scss';

const SignupConfirmation: React.FC<{ moreNewslettersLink: string; privacyPolicyLink: string }> = ({
  moreNewslettersLink,
  privacyPolicyLink,
}) => {
  const handleClick = () => {
    window.location.assign(moreNewslettersLink);
  };

  return (
    <div className={styles.container} data-testid="newsletter-signup-inline-confirmation">
      <span className={styles.title}>Thank you for subscribing!</span>
      <div className={styles['bottom-container']}>
        <div className={styles['cta-text']}>We have more newsletters</div>
        <CTA
          design="primary"
          label="Show me"
          ariaLabel="Show me"
          dataTmDataTrack="newsletter-additional"
          onClick={handleClick}
        />
        <div className={styles['privacy-container']}>
          <p className={styles.disclaimer}>
            See our <a href={privacyPolicyLink}>Privacy Notice</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupConfirmation;
