import { publicationFont } from '@/helpers/utils/fonts';
import styles from './styles/HeadingSix.module.scss';

interface HeadingSixProps extends React.PropsWithChildren {
  publication?: string;
  className?: string;
}

const HeadingSix: React.FC<HeadingSixProps> = ({ children, publication, className }) => {
  const fontClass = publicationFont(publication);
  const combinedClasses = `${fontClass} ${styles['heading-six']} ${className ?? ''}`.trim();

  return <h6 className={combinedClasses}>{children}</h6>;
};

export default HeadingSix;
