'use client';

import { useRef } from 'react';
import { AffiliateStickyContentData } from '@/commercial/types';
import useAffiliateStickyContentStore from '../../store/affiliateStickyContent/affiliateStickyContent';

function AffiliateStickyContentStoreInitializer({
  affiliateStickyContentData,
}: {
  affiliateStickyContentData: AffiliateStickyContentData | null;
}) {
  const initialized = useRef(false);
  const {
    actions: { addAffiliateStickyContentToStore },
  } = useAffiliateStickyContentStore();

  if (!initialized.current) {
    addAffiliateStickyContentToStore({ affiliateStickyContent: affiliateStickyContentData });
    initialized.current = true;
  }
  return null;
}

export default AffiliateStickyContentStoreInitializer;
