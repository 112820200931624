'use client';

import Strong from '@/components/atoms/Strong/Strong';
import Link from 'next/link';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import styles from './styles/AuthorLink.module.scss';

interface AuthorLinkProps {
  profileUri: string;
  authorName?: string;
}

const AuthorLink: React.FC<AuthorLinkProps> = ({ profileUri, authorName }) => (
  <AnalyticsTrack
    analyticsData={linkHelper('BYLINE_AUTHOR_PROFILE', profileUri, {
      componentTitle: '',
      componentID: '',
      componentText: authorName,
    })}
  >
    <Link className={styles['author-link']} data-tmdatatrack="author" data-testid="byline-author" href={profileUri}>
      <Strong>{authorName}</Strong>
    </Link>
  </AnalyticsTrack>
);

export default AuthorLink;
