'use client';

import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import SocialFollowBar from '@/components/molecules/SocialFollowBar/SocialFollowBar';
import KnowledgeRecommender from '@/components/organisms/KnowledgeRecommender/KnowledgeRecommender';
import Breadcrumbs from '@/components/molecules/Breadcrumbs/Breadcrumbs';
import ReadNext from '@/components/organisms/ReadNext/ReadNext';
import { ArticleTemplateProps } from '@/ts';
import RHRecommender from '@/components/organisms/RHRecommender/RHRecommender';
import CommentBox from '@/components/atoms/CommentBox/CommentBox';
import CommentingBox from '@/components/organisms/CommentingBox/CommentingBox';
import LinkbyPubFeed from '@/components/atoms/LinkbyPubFeed/LinkbyPubFeed';
import { isNavigatorOnly } from '@/helpers/utils/publication';
import TopSlotBox from '@/components/organisms/TopBoxWidget/TopSlotBox';
import runExperiment from '@/helpers/growthbook/runExperiment';
import ArticleBody from '../../organisms/ArticleBody/ArticleBody';
import styles from './styles/Article.module.scss';

const ArticleTemplate: React.FC<ArticleTemplateProps> = ({
  children,
  publication,
  publicationName,
  socialPlatforms,
  breadcrumbsEnabled,
  breadcrumbsData,
  rightHandRail,
  knowledgeRecommenderEnabled,
  readNextEnabled,
  socialFollowBarEnabled,
  recommendedEnabled,
  disableCommercial,
  isAuthFlowEnabled,
  commentingBoxEnabled,
  commentingEnabled,
  contentSubType,
  isEvergreen,
  appsUrls,
  vfUUId,
  vfContainerId,
  affiliateStickyBarEnabled,
  linkbyPubFeedEnabled,
  LinkbyScript,
  rightHandRailEnabled,
  growthbookEnabled,
  shopWindowConfig,
}) => {
  const isAffiliate = contentSubType === 'affiliate';
  const showCommercialBox = !isAffiliate;
  const NAVIGATOR_ONLY = isNavigatorOnly(publication);
  const showRHR = rightHandRailEnabled && !(isAffiliate && isEvergreen);
  const isAffiliateStickyBarEnabled = isAffiliate && affiliateStickyBarEnabled;
  const isLinkbyPubFeedEnabled = linkbyPubFeedEnabled;
  const topSlotExperimentVariant = growthbookEnabled ? runExperiment({ feature: 'nav-5244' }) : null;

  return (
    <>
      {showRHR && (topSlotExperimentVariant === '1' || topSlotExperimentVariant === '2') ? (
        <TopSlotBox indexStart={0} shopWindowConfig={shopWindowConfig} experimentVariant={topSlotExperimentVariant} />
      ) : null}
      <div className={styles.wrapper}>
        <div className={styles['inner-wrapper']}>
          {breadcrumbsEnabled && (
            <Breadcrumbs publication={publication} label="label" linkData={breadcrumbsData} dataTmDataTrack="nav" />
          )}
          <ArticleBody dataTestId="body" publication={publication}>
            {children}
          </ArticleBody>
          {knowledgeRecommenderEnabled && <KnowledgeRecommender sectionTitle="Read Next" publication={publication} />}
          {readNextEnabled && !isAffiliate && (
            <ReadNext title="READ NEXT" publication={publication} dataTestId="read-next-inline" />
          )}
          <LinkbyPubFeed enabled={isLinkbyPubFeedEnabled && isAffiliate} url={LinkbyScript} />
          {socialFollowBarEnabled && (
            <SocialFollowBar
              align="left"
              socialButtons={{
                socialPlatforms,
                designType: 'secondary',
              }}
              dataTmDataTrack="social-follow"
              publication={publication}
              publicationName={publicationName}
              dataTestId="social-follow"
              commentingBoxEnabled={!!commentingBoxEnabled}
              vfContainerId={vfContainerId}
              vfUUId={vfUUId}
            />
          )}
          {isAffiliateStickyBarEnabled && (
            <div data-testid="affiliate-sticky-bar-end" id="affiliate-sticky-bar-end"></div>
          )}
          {disableCommercial !== true && (
            <div className={styles['below-article-commercial-box']}>
              <CommercialBox location={'below-article'} position={1} />
            </div>
          )}
          {commentingBoxEnabled && (
            <CommentBox
              isAuthFlowEnabled={isAuthFlowEnabled}
              commentingEnabled={commentingEnabled}
              containerId={vfContainerId}
            />
          )}
          {commentingBoxEnabled !== null && commentingBoxEnabled !== true && !NAVIGATOR_ONLY ? (
            <CommentingBox
              title="Looking for the comments?"
              publication={publication}
              content="We're currently working on our commenting system. In the meantime you can still comment from within the app."
              appsUrls={appsUrls}
            />
          ) : (
            <></>
          )}
        </div>
        {showRHR && (
          <>
            <hr className={styles['h-line']} />
            <aside className={styles['right-aside-wrapper']}>
              {showCommercialBox && <CommercialBox location={'aside'} position={1} />}
              {recommendedEnabled && (
                <RHRecommender
                  publication={publication}
                  rightHandRail={rightHandRail}
                  showCommercialBox={showCommercialBox}
                />
              )}
              {!recommendedEnabled && showCommercialBox && (
                <>
                  {<CommercialBox location={'aside'} position={2} />}
                  {<CommercialBox location={'aside'} position={3} />}
                  {<CommercialBox location={'aside'} position={4} />}
                  {<CommercialBox location={'aside'} position={5} />}
                  {<CommercialBox location={'aside'} position={6} />}
                  {<CommercialBox location={'aside'} position={7} />}
                  {<CommercialBox location={'aside'} position={8} />}
                </>
              )}
              {showCommercialBox && <CommercialBox location={'below-aside'} position={1} />}
            </aside>
          </>
        )}
      </div>
    </>
  );
};

export default ArticleTemplate;
