import { useEffect, useState, useCallback, useRef } from 'react';
import useFeliz from '@/store/feliz/feliz';
import CONSTANTS from '@/constants/webAlerts';
import useConsentDataService from '@/store/consent/consent';
import { consentSignalReady } from '@/store/consent/selectors';
import { AnalyticsData } from '@/ts';
import {
  hasExistingLocalStorage,
  checkScrollPosition,
  getPreviousPushProvider,
  getPreviousTags,
  hasGrantedCurrentSegment,
} from '@/services/webAlerts/useWebAlertModalUtils';
import { handleSubscribeToWebAlertsOS } from '@/services/webAlerts/webAlerts';

const { AIRSHIP, PERMISSION } = CONSTANTS;

declare global {
  interface Window {
    UA?: Promise<{
      channel?: {
        id?: string;
        tags?: any;
        optOut?: any;
      };
    }>;
    OneSignal?: Promise<{
      setConsentGiven?: any;
      User?: {
        onesignalId?: string;
        addEventListener?: any;
        addTags?: any;
        PushSubscription?: {
          _id: string | undefined;
        };
      };
      Notifications?: any;
    }>;
  }
}

const useWebAlertModal = (
  currentSegment: string,
  oneSignalEnabled: boolean,
  segmentTag: string,
  segmentScope: string,
  clickEvent: Function,
  analytics: AnalyticsData
) => {
  const felizStore = useFeliz();
  const [webAlertsSDKLoaded, setWebAlertsSDKLoaded] = useState<boolean>(false);
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [hasInteractedPreviously, setHasInteractedPreviously] = useState<boolean>(true);
  const [hasBrowserPermission, setHasBrowserPermission] = useState<boolean>(false);
  const [isFelizEnabled, setIsFelizEnabled] = useState<boolean>(false);
  const [isCorrectScrollPosition, setIsCorrectScrollPosition] = useState<boolean>(false);
  const hasReachedScrollPosition = useRef<boolean>(false);
  const consentStore = useConsentDataService();
  const cmpReady = consentSignalReady(consentStore);
  const [oneSignalId, setOneSignalId] = useState<string | undefined>(undefined);

  const updateScrollPosition = useCallback(() => {
    if (!hasReachedScrollPosition.current) {
      const scrollPositionMet = checkScrollPosition();
      if (scrollPositionMet) {
        setIsCorrectScrollPosition(true);
        hasReachedScrollPosition.current = true;
      }
    }
  }, []);

  useEffect(() => {
    const performCheck = async () => {
      let isWebAlertsSDKLoaded;
      if (oneSignalEnabled) {
        isWebAlertsSDKLoaded = await window.OneSignal;
        setOneSignalId(isWebAlertsSDKLoaded?.User?.onesignalId);
      } else {
        isWebAlertsSDKLoaded = await window.UA;
      }
      if (isWebAlertsSDKLoaded) setWebAlertsSDKLoaded(true);
    };

    if (webAlertsSDKLoaded) {
      window.removeEventListener('web.alerts.sdk.snippet.loaded', performCheck);
    } else {
      window.addEventListener('web.alerts.sdk.snippet.loaded', performCheck);
    }
  }, [webAlertsSDKLoaded]);

  useEffect(() => {
    /*
      IF oneSignalEnabled is true then this useEffect manages the migration of users from airship into onesignal as well as state item hasInteractedPreviously
      IF oneSignalEnabled is false then this only checks for local storage for the current segment and manages hasInteractedPreviously state item.
    */
    const performCheck = async () => {
      const storageItemExists = await hasExistingLocalStorage(currentSegment, oneSignalEnabled);
      if (oneSignalEnabled) {
        /* Below we are checking the following:
          IF NOT subscribed to onesignal already
          IF they have a current airship channel
          IF NOT subscribed to current segment
          if all the above are true then we are creating a new subscription to onesignal including previous tags
          We will then continue to show the user the popup as they are not subscribed to the current segment
        */
        const hasAcceptedPromptPreviously = storageItemExists ? hasGrantedCurrentSegment(currentSegment) : false;
        if (window.UA && !oneSignalId) {
          const sdk = await window.UA;
          const hasAirshipSubscription = !!sdk.channel?.id;
          if (hasAirshipSubscription && !hasAcceptedPromptPreviously) {
            const previousTags = await getPreviousTags(segmentTag);
            handleSubscribeToWebAlertsOS(
              window.location.hostname,
              window.location.href,
              clickEvent,
              analytics,
              segmentTag,
              segmentScope,
              true,
              previousTags,
              false
            );
          }
        }

        /* Below we are checking the following:
          IF previously subscribed to current segment (storage item exisists)
          IF previous provider is airship
          IF user has previoulsy granted browser permission
          if ALL THREE above are true then we create new subscription with onesignal including previous tags
          We will not show user the popup as they are already subscribed to this segment
        */
        const permission = 'Notification' in window && Notification.permission;
        const previousPushProvider = getPreviousPushProvider();
        const isMigratingUser =
          permission === PERMISSION.GRANTED &&
          storageItemExists &&
          hasAcceptedPromptPreviously &&
          !!currentSegment &&
          previousPushProvider === AIRSHIP;
        if (isMigratingUser) {
          const previousTags = await getPreviousTags(segmentTag);

          handleSubscribeToWebAlertsOS(
            window.location.hostname,
            window.location.href,
            clickEvent,
            analytics,
            segmentTag,
            segmentScope,
            true,
            previousTags,
            true
          );
        }

        setHasInteractedPreviously(storageItemExists);
      } else {
        setHasInteractedPreviously(storageItemExists);
      }
    };

    if (segmentScope && segmentTag) {
      if (oneSignalEnabled) {
        if (webAlertsSDKLoaded) {
          performCheck();
        }
      } else {
        performCheck();
      }
    }
  }, [currentSegment, webAlertsSDKLoaded, oneSignalId, segmentTag, segmentScope]);

  useEffect(() => {
    setIsFelizEnabled(!!felizStore?.state?.client.webAlert?.notification);
  }, [felizStore?.state?.client.webAlert?.notification]);

  useEffect(() => {
    const permission = 'Notification' in window && Notification.permission;
    setHasBrowserPermission(!!permission && permission !== 'denied');
  }, []);

  useEffect(() => {
    updateScrollPosition();
    window.addEventListener('scroll', updateScrollPosition);
    return () => window.removeEventListener('scroll', updateScrollPosition);
  }, [updateScrollPosition]);

  useEffect(() => {
    if (!hasInteractedPreviously && hasBrowserPermission && isFelizEnabled && isCorrectScrollPosition && cmpReady) {
      setShouldShowModal(true);
    } else {
      setShouldShowModal(false);
    }
  }, [hasInteractedPreviously, hasBrowserPermission, isFelizEnabled, isCorrectScrollPosition, cmpReady]);

  return shouldShowModal;
};

export default useWebAlertModal;
