'use client';

import { useLoginStoreContext } from '@/contexts/login/loginProvider';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import gtmPush from '@/helpers/analytics/dataLayer';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import styles from './styles/CommentBox.module.scss';

export interface CommentBoxProps {
  /**
   * When `user` is provided, `Avatar` component displays users' initials
   */
  isAuthFlowEnabled?: boolean;
  containerId?: string;
  testId?: string;
  commentingEnabled?: boolean;
}

const CommentBox: React.FC<CommentBoxProps> = ({
  isAuthFlowEnabled = false,
  containerId = 'any-random-article-id', // men dev container id this is uuid for dev enviornment pubs-> '00000000-0000-4000-8000-c6ead506e9b5'
  testId = 'viafoura-wrapper',
  commentingEnabled = false,
}) => {
  const commentAdPosRef = useRef(0);
  const { isLoggedIn, logoutAction, openModal, authUser } = useLoginStoreContext((store: any) => ({
    isLoggedIn: store.state.client.isAuthenticated,
    authUser: store.state.client.authUser,
    logoutAction: store.actions.logout,
    openModal: store.actions.openModal,
  }));
  const isLoggedInRef = useRef(isLoggedIn);
  const authUserRef = useRef(authUser);

  const getToken = () => {
    if (isLoggedInRef.current) {
      const tokenStr = localStorage.getItem('auth-token');
      return tokenStr ? JSON.parse(tokenStr).accessToken : null;
    }
    return localStorage.getItem('loginRadiusToken');
  };

  const loginVf = () => {
    window?.vf?.session.login.loginRadius(getToken());
  };

  const logout = () => {
    logoutAction();
    window?.vf?.session.logout();
  };

  const handleAuthEvent = () => {
    window.addEventListener('auth-ui.authentication', (event) => {
      const { isAuthenticated } = event.detail;
      if (isAuthenticated) {
        loginVf();
      } else {
        logout();
      }
    });
  };

  const generateCommentAd = (slotName: string) => {
    const commentPlaceholder: HTMLElement | null = slotName ? document.getElementById(slotName) : null;
    if (!commentPlaceholder) {
      return;
    }
    const slotContainer: HTMLElement | null = commentPlaceholder.closest('.vf-promo-gtag');
    if (slotContainer) {
      slotContainer.style.display = 'block';
    }
    const root = createRoot(commentPlaceholder);
    commentAdPosRef.current += 1;
    root.render(<CommercialBox location="comment-section" position={commentAdPosRef.current} />);
  };

  const trackViafouraEvents = () => {
    const data = {
      event: 'CommentAdded',
      componentCategory: 'DirectEngagement',
      componentType: 'Commenting',
      linkURL: window.location.href, // dynamic, as per actual link URL' if applicable
    };
    window.vf.$subscribe('comment', 'created', () => {
      gtmPush({ ...data, event: 'CommentAdded', componentAction: 'Comment Added' });
    });
    window.vf.$subscribe('comment-reply', 'posted', () => {
      gtmPush({ ...data, event: 'CommentReplied', componentAction: 'Comment Replied' });
    });
    window.vf.$subscribe('vf-ads', 'requestCommentAd', generateCommentAd);
  };

  const handleViafouraEvents = () => {
    window.vf.$prepublish((channel: any, event: any, ...args: any) => {
      if (channel === 'authentication' && event === 'needed') {
        if (isLoggedInRef.current && authUserRef.current) {
          if (authUserRef.current?.UserName && authUserRef.current.UserName !== authUserRef.current?.Email[0].Value) {
            loginVf();
          } else {
            window.dispatchEvent(
              new CustomEvent('auth-ui.complete-profile', {
                detail: { origin: 'comments', fields: ['username'] },
              })
            );
          }
        } else {
          handleAuthEvent();
          const triggerLoginFlow = 'auth-ui.authenticate';
          openModal(triggerLoginFlow, 'comments');
        }
        return false; // blocks tray from opening when not needed
      }

      if (channel === 'authentication' && event === 'logout') {
        logout();
        return false; // blocks tray from opening when not needed
      }
      return { channel, event, args };
    });
  };

  useEffect(() => {
    window.vfQ = window.vfQ || [];
    isLoggedInRef.current = isLoggedIn;
    authUserRef.current = authUser;
    window.vfQ.push(async () => {
      // viafoura is loaded and window.vf is available
      if (isLoggedInRef.current && commentingEnabled && isAuthFlowEnabled) {
        if (
          authUserRef.current &&
          authUserRef.current?.UserName &&
          authUserRef.current.UserName !== authUserRef.current?.Email[0].Value
        ) {
          loginVf();
        }
      }
      if (!isLoggedInRef.current && commentingEnabled && isAuthFlowEnabled) {
        window?.vf?.session.logout();
        handleViafouraEvents();
        trackViafouraEvents();
      }
    });
  }, [isLoggedIn, authUser]);

  const styleOverride = { overflow: 'clip' } as React.CSSProperties;
  return (
    <div id="comments-wrapper" data-testid={testId} className={styles['comments-wrapper']} style={styleOverride}>
      <CommercialBox location="above-comment-section" position={1} />
      <div className="viafoura">
        <vf-conversations vf-container-id={containerId}></vf-conversations>
      </div>
    </div>
  );
};

export default CommentBox;
