import { BadgeProps } from '@/ts';
import styles from './styles/Badge.module.scss';

const Badge: React.FC<BadgeProps> = ({ linkUrl, linkText, className }) => (
  <a className={`${styles['badge-link']} ${className ? styles[className] : ''}`} href={linkUrl}>
    {linkText}
  </a>
);

export default Badge;
