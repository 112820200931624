import React from 'react';
import QuizConstants from '../../../../constants/quiz';
import styles from './styles/QuizResult.module.scss';

export interface QuizResultProps {
  scoreRemarks: {
    upToScore: number;
    remarkText: string;
    socialShareText: string;
  }[];
  score: string;
}

const QuizResult: React.FC<QuizResultProps> = ({ scoreRemarks, score }) => {
  // Parse the score as a number
  const parsedScore = parseInt(score, 10);

  // Find the appropriate remark based on the score
  let remark = '';
  for (let i = 0; i < scoreRemarks.length; i += 1) {
    const remarkData = scoreRemarks[i];
    if (parsedScore <= remarkData.upToScore) {
      remark = remarkData.remarkText;
      break; // Exit the loop once a matching remark is found
    }
  }

  return (
    <>
      <div className={styles['quiz-result']}>
        <h6 className={styles['result-message']}>
          {QuizConstants.THANKS_MESSAGE}
          {remark && <span className={styles['result-message']}>{remark}</span>}
        </h6>
        <span className={styles['sub-heading']}>{QuizConstants.SCORED_MESSAGE}</span>
        <span className={styles.score}>{score}</span>
      </div>
    </>
  );
};

export default QuizResult;
