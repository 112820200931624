import { curry } from 'lodash';
import { SizeMapping, IHomepageTeaserCrops, ImageAttributes } from '@/ts';
import IMAGE from '../../constants/image';
import { getImageString } from '../agnosticTransformer/article/utilities';
import getNumericRatio from './ratio';

function generateSrcset(imageUrl: string): string {
  const sizeMappings: SizeMapping[] = IMAGE.sizeMappings.imageEmbed;

  const srcset: string = sizeMappings
    .map(({ size, width }: SizeMapping) => {
      const url: string = imageUrl.replace('/ALTERNATES/s810/', `/ALTERNATES/${size}/`);
      return `${url} ${width}w`;
    })
    .join(', ');

  return srcset;
}

export const transformImage = (imageUrl: string, image: any, imageCrops: IHomepageTeaserCrops) => {
  if (!image || !image.id) return null;
  if (!imageCrops) {
    return null;
  }

  const {
    images: { standardTeaser, sizes },
  } = imageCrops;

  const { small, medium } = standardTeaser;
  const { title, id } = image;
  const imageCreator = curry(getImageString)(title, id);
  const primaryCropValue = medium.crop;
  const src = imageCreator(imageUrl, primaryCropValue);
  const srcSet = `${imageCreator(imageUrl, small.crop)} ${small.size}, ${imageCreator(imageUrl, primaryCropValue)} ${
    medium.size
  }`;

  return { src, srcSet, sizes };
};

export const setImageAttributes = (image: ImageAttributes | null, priority: boolean, aspectRatio: string) => {
  if (!image) {
    return image;
  }
  const loading = priority ? 'lazy' : 'eager';
  const fetchpriority = priority ? 'low' : 'high';
  return { ...image, loading, fetchpriority, width: Math.round(getNumericRatio(aspectRatio) * 100), height: 100 };
};

export default generateSrcset;
