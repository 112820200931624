'use client';

import {
  CuriouslySquareIcon,
  DailyRecordSquareIcon,
  DailyStarSquareIcon,
  LiverpoolEchoSquareIcon,
  MENSquareIcon,
  BirminghamMailSquareIcon,
  MirrorSquareIcon,
  BristolPostSquareIcon,
  AllOutFightingSquareIcon,
  TheMirrorSquareIcon,
  WalesOnlineSquareIcon,
  IrishStarSquareIcon,
  AllOutGamingSquareIcon,
} from '@/components/atoms/Icons/publications';
import PUBLICATIONS from '@/constants/publications';
import styles from './styles/SquareLogo.module.scss';

const {
  CURIOUSLY,
  LIVERPOOL_ECHO,
  MEN,
  DAILY_RECORD,
  DAILY_STAR,
  BIRMINGHAM_MAIL,
  MIRROR,
  BRISTOL_POST,
  ALLOUTFIGHTING,
  THE_MIRROR,
  WALES_ONLINE,
  IRISH_STAR,
  ALLOUTGAMING,
} = PUBLICATIONS;

interface LogoProps {
  publication: string;
  size: string;
}

const getPublicationLogo = (publication: string, size: string) => {
  interface Map {
    [key: string]: React.ReactNode;
  }

  const logo: Map = {
    [CURIOUSLY]: <CuriouslySquareIcon size={size} />,
    [LIVERPOOL_ECHO]: <LiverpoolEchoSquareIcon size={size} />,
    [MEN]: <MENSquareIcon size={size} />,
    [DAILY_RECORD]: <DailyRecordSquareIcon size={size} />,
    [DAILY_STAR]: <DailyStarSquareIcon size={size} />,
    [BIRMINGHAM_MAIL]: <BirminghamMailSquareIcon size={size} />,
    [MIRROR]: <MirrorSquareIcon size={size} />,
    [BRISTOL_POST]: <BristolPostSquareIcon size={size} />,
    [ALLOUTFIGHTING]: <AllOutFightingSquareIcon size={size} />,
    [WALES_ONLINE]: <WalesOnlineSquareIcon size={size} />,
    [THE_MIRROR]: <TheMirrorSquareIcon size={size} />,
    [IRISH_STAR]: <IrishStarSquareIcon size={size} />,
    [ALLOUTGAMING]: <AllOutGamingSquareIcon size={size} />,
  };

  return logo[publication];
};

const Logo: React.FC<LogoProps> = ({ publication, size }) => (
  <div data-testid="square-logo" className={(styles['square-logo'], styles[size])}>
    {getPublicationLogo(publication, size)}
  </div>
);

export default Logo;
