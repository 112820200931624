'use client';

import { useRef } from 'react';
import { PageData } from '@/ts';
import usePage from '../../store/page/page';

function PageStoreInitializer({ articleData }: { articleData: PageData }) {
  const initialized = useRef(false);
  const {
    actions: { addPageDataToStore },
  } = usePage();

  if (!initialized.current) {
    addPageDataToStore(articleData);
    initialized.current = true;
  }

  return null;
}

export default PageStoreInitializer;
