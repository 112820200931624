'use client';

import { useEffect, useRef } from 'react';
import { HtmlEmbedProps } from '@/ts/interfaces/htmlEmbed';
import useConsentDataService from '@/store/consent/consent';
import { isVendorConsented } from '@/store/consent/selectors';
import EmbedPlaceholder from './EmbedPlaceholder';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import styles from './styles/HtmlEmbed.module.scss';

declare global {
  interface Window {
    twttr: {
      widgets: {
        load: (element?: HTMLElement) => void;
      };
    };
  }
}

const HtmlEmbed: React.FC<HtmlEmbedProps> = ({
  dataTestId,
  html,
  embedType,
  rightHandRailEnabled = false,
  publication,
}) => {
  const consentStore = useConsentDataService();
  const consentedTikTok = isVendorConsented('bytedance')(consentStore);
  const consentedInstagram = isVendorConsented('facebook')(consentStore);
  const consentedTwitter = isVendorConsented('twitter')(consentStore);
  const ref = useRef<HTMLDivElement>(null);

  const isTiktok = embedType === 'tiktok';
  const isInstagram = embedType === 'instagram';
  const isTwitter = embedType === 'twitter';

  const enablePlaceholder =
    (isTiktok && !consentedTikTok) || (isInstagram && !consentedInstagram) || (isTwitter && !consentedTwitter);

  useEffect(() => {
    if (ref.current && !enablePlaceholder) {
      ref.current.innerHTML = html;

      const scriptTags = Array.from(ref.current.getElementsByTagName('script'));
      scriptTags.forEach((script) => {
        const newScript = document.createElement('script');
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.innerHTML = script.innerHTML;
        }
        document.body.appendChild(newScript);
      });
    }
  }, [html, enablePlaceholder]);

  const embedClass = embedType === 'data_unit' ? styles['data-unit'] : styles[`${embedType}`];

  return (
    <ErrorBoundaryFallback>
      <div className={styles.container}>
        {enablePlaceholder ? (
          <EmbedPlaceholder publication={publication} />
        ) : (
          <div
            className={`${embedClass} ${rightHandRailEnabled ? styles['right-handrail-enabled'] : ''}`}
            data-testid={dataTestId}
            ref={ref}
          ></div>
        )}
      </div>
    </ErrorBoundaryFallback>
  );
};

export default HtmlEmbed;
