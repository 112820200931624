import Logo from '@/components/molecules/Logos/Square';
import TextLink from '@/components/atoms/TextLink/TextLink';
import ArrowRight from '@/components/atoms/Icons/ArrowRight';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import curry from 'lodash/curry';
import { BreadcrumbsProps } from '@/ts/interfaces/breadcrumbs';
import styles from './style/Breadcrumb.module.scss';

const analyticsHelper = curry(linkHelper)('BREADCRUMBS');

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ label, linkData, dataTmDataTrack, publication }) => (
  <nav className={styles['breadcrumb-nav']}>
    <ul aria-label={label}>
      <li className={styles['logo-item']}>
        <AnalyticsTrack
          analyticsData={analyticsHelper('/', {
            componentTitle: 'TopArticle|Subcategories|Home',
            componentID: '',
            componentText: `${publication} Home`,
            componentLocation: 'header',
          })}
        >
          <TextLink
            href="/"
            dataTmDataTrack="nav"
            dataTrackName="TopArticle|Subcategories|Home"
            dataTmDataTrackLocation="header"
            ariaLabel={`${publication} Home`}
          >
            <Logo publication={publication} size="xsmall" />
          </TextLink>
        </AnalyticsTrack>
        <ArrowRight />
      </li>
      {linkData?.map((item, index) => (
        <li className={styles['list-item']} key={item.linkName}>
          <AnalyticsTrack
            analyticsData={analyticsHelper(item.href, {
              componentTitle: item.linkName,
              componentID: '',
              componentText: index === 0 ? `${publication} Home` : item.linkName,
              componentLocation: dataTmDataTrack,
            })}
          >
            <TextLink
              {...item}
              dataTmDataTrack={dataTmDataTrack}
              dataTrackName={item.linkName}
              ariaLabel={index === 0 ? `${publication} Home` : item.linkName}
            >
              {item.linkName}
            </TextLink>
          </AnalyticsTrack>
          {index < linkData.length - 1 && <ArrowRight />}
        </li>
      ))}
    </ul>
  </nav>
);

export default Breadcrumbs;
