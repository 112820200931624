'use client';

import useWebAlertModal from '@/hooks/useWebAlertModal';
import { useEffect, useState, useRef } from 'react';
import { TransformedTagData, WebAlertsConfig } from '@/ts';
import Modal from '@/components/atoms/Modal/Modal';
import handleSubscribeToWebAlerts, { handleSubscribeToWebAlertsOS } from '@/services/webAlerts/webAlerts';
import { setWebAlertsLocalStorage } from '@/services/webAlerts/webAlertUtils';
import CONSTANTS from '@/constants/webAlerts';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import WebAlert from './WebAlert';

export interface WebAlertModalProps {
  publication: string;
  webAlertsConfig: WebAlertsConfig;
  tags: TransformedTagData[];
  oneSignalEnabled: boolean;
}

const WebAlertModal: React.FC<WebAlertModalProps> = ({ publication, webAlertsConfig, tags, oneSignalEnabled }) => {
  const publicationHostname = useRef<string>('');
  const url = useRef<string>('');
  const [showModal, setShowModal] = useState(false);
  const [segmentScope, setSegmentScope] = useState('');
  const [segmentTag, setSegmentTag] = useState('');
  const [popupTitle, setPopupTitle] = useState('');
  const [iconName, setIconName] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const { WEB_ALERT_STORAGE_NAME, PERMISSION, TTL } = CONSTANTS;
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);
  const showModalBasedOnChecks = useWebAlertModal(
    `${publicationHostname.current}${segmentScope}`,
    oneSignalEnabled,
    segmentTag,
    segmentScope,
    clickEvent,
    analytics
  );

  useEffect(() => {
    const { hostname, href } = window.location;
    publicationHostname.current = `${hostname}`;
    url.current = href;

    return () => {
      publicationHostname.current! = null!;
      url.current! = null!;
    };
  }, []);

  useEffect(() => {
    const { segments } = webAlertsConfig;

    const setDefaultValues = () => {
      setPopupTitle(webAlertsConfig.defaultTitle);
      setSegmentScope(webAlertsConfig.defaultScope);
      setSegmentTag(webAlertsConfig.defaultSegmentName);
      setIconName(webAlertsConfig.defaultIcon);
    };

    if (!segments || !segments.length || popupTitle) {
      if (!popupTitle) {
        setDefaultValues();
      }
      return;
    }

    const tagMatches = segments
      .filter((segment) => tags.some((tag) => segment.tag === tag.name))
      .map((segment) => {
        const match = tags.find((tag) => segment.tag === tag.name);
        return { ...segment, isPrimary: match?.isPrimary || false };
      });

    const currentUrl = window.location.href;
    const scopeMatches = segments.filter((segment) => currentUrl.includes(segment.scope));

    if (tagMatches.length) {
      const primaryTag = tagMatches.filter((tag) => tag.isPrimary);
      if (primaryTag.length) {
        setPopupTitle(primaryTag[0].title);
        setSegmentScope(primaryTag[0].scope);
        setSegmentTag(primaryTag[0].segmentName);
        setIconName(primaryTag[0].icon);
      } else {
        setPopupTitle(tagMatches[0].title);
        setSegmentScope(tagMatches[0].scope);
        setSegmentTag(tagMatches[0].segmentName);
        setIconName(tagMatches[0].icon);
      }
    } else if (scopeMatches.length) {
      setPopupTitle(scopeMatches[0].title);
      setSegmentScope(scopeMatches[0].scope);
      setSegmentTag(scopeMatches[0].segmentName);
      setIconName(scopeMatches[0].icon);
    } else {
      setDefaultValues();
    }
  }, [popupTitle]);

  useEffect(() => {
    if (iconName && !iconUrl) {
      const iconString = `/assets/${publication}/logos/web-alerts/web-alert-${iconName}.png`;
      setIconUrl(iconString);
    }
  }, [iconName, iconUrl]);

  useEffect(() => {
    if (showModalBasedOnChecks && popupTitle && segmentScope && iconUrl && iconName) {
      setShowModal(true);
      clickEvent('WEB_ALERTS:REACH_PROMPT_DISPLAYED', { componentTitle: '', linkURL: url.current, ...analytics });
    }
  }, [showModalBasedOnChecks, popupTitle, segmentScope, iconUrl, iconName]);

  const handleClose = () => {
    setWebAlertsLocalStorage(
      `${WEB_ALERT_STORAGE_NAME}:${publicationHostname.current}${segmentScope}`,
      PERMISSION.DENIED,
      TTL.DECLINE
    );
    clickEvent('WEB_ALERTS:REACH_PROMPT_DECLINED', { componentTitle: '', linkURL: url.current, ...analytics });

    setShowModal(false);
  };

  const handleSubscribe = () => {
    if (oneSignalEnabled) {
      handleSubscribeToWebAlertsOS(
        publicationHostname.current,
        url.current,
        clickEvent,
        analytics,
        segmentTag,
        segmentScope,
        false,
        [],
        true
      );
    } else {
      handleSubscribeToWebAlerts(
        webAlertsConfig.vapidPublicKey,
        publicationHostname.current,
        url.current,
        clickEvent,
        analytics,
        segmentTag,
        segmentScope
      );
    }
    clickEvent('WEB_ALERTS:REACH_PROMPT_ACCEPTED', { componentTitle: '', linkURL: url.current, ...analytics });
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <Modal
          data-testid="web-alert-modal"
          onClose={() => {
            setShowModal(false);
          }}
          outsideClickClose={false}
          preventBodyScroll={false}
        >
          <WebAlert
            data-testid="web-alert-component"
            publicationName={popupTitle}
            onClose={handleClose}
            subscribe={handleSubscribe}
            iconUrl={iconUrl}
          />
        </Modal>
      )}
    </>
  );
};

export default WebAlertModal;
