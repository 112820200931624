'use client';

import styled from 'styled-components';

const LiItem = styled.li`
  margin: 0;
  padding: 0;
`;

export default LiItem;
