'use client';

import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Image from 'next/image';
import CTA from '@/components/atoms/CTA/CTA';
import styles from './style/WebAlert.module.scss';

export interface WebAlertProps {
  publicationName: string;
  onClose: () => void;
  subscribe: () => void;
  iconUrl: string;
}

const WebAlert: React.FC<WebAlertProps> = ({ publicationName, onClose, subscribe, iconUrl }) => (
  <div className={styles['web-alert-main-container']}>
    <div className={styles['web-alert-container']} data-testid="WebAlert">
      <Image src={iconUrl} width={48} height={48} alt={publicationName} />
      <Paragraph>{publicationName} uses notifications to keep you updated</Paragraph>
      <CTA
        design="primary"
        label="Keep me updated"
        dataTestId="submit"
        dataTmDataTrack="web-alert-subjective"
        onClick={subscribe}
      />
      <CTA
        design="white"
        label="No thanks"
        dataTestId="cancel"
        dataTmDataTrack="web-alert-subjective-close"
        onClick={onClose}
      />
    </div>
  </div>
);

export default WebAlert;
