import { curry } from 'lodash';
import { CustomImgHTMLAttributes, ICropStructure, IHomepageTeaserCrops, Tag, TeaserImage } from '@/ts';
import { getImageString } from './article/utilities';
import { transformImage } from '../utils/imageUtils';
import getNumericRatio from '../utils/ratio';

export const transformPicture = (imageUrl: string, image: any, imageCrops: ICropStructure) => {
  if (!imageCrops) {
    return null;
  }
  const { small, medium, large } = imageCrops;

  const { file, id } = image;
  const imageCreator = curry(getImageString)(file, id);
  const src = imageCreator(imageUrl, medium.crop);

  const sources = [
    {
      media: `${small.viewport}`,
      srcSet: `${imageCreator(imageUrl, small.crop || '')} ${small.size}`,
    },
    {
      media: `${medium.viewport}`,
      srcSet: `${imageCreator(imageUrl, medium.crop || '')} ${medium.size}`,
    },
    {
      media: `${large.viewport}`,
      srcSet: `${imageCreator(imageUrl, large.crop || '')} ${large.size}`,
    },
  ];

  return {
    src,
    sources,
  };
};

export const transformTags = (primaryTag: Tag, tagUrl: string) => ({
  tagName: primaryTag.name,
  tagUrl: `${tagUrl}/${primaryTag.term}`,
});

export const transformTeaserImage = (
  imageUrl: string,
  image: any,
  imageCrops: IHomepageTeaserCrops
): TeaserImage | null => {
  if (!image?.id) {
    return null;
  }

  const {
    images: { wobTeaser, standardTeaserPrimaryLarge },
  } = imageCrops;

  return {
    standardTeaser: transformImage(imageUrl, image, imageCrops),
    wobTeaser: transformPicture(imageUrl, image, wobTeaser),
    standardTeaserPrimaryLarge: transformPicture(imageUrl, image, standardTeaserPrimaryLarge),
  };
};

export const setTeaserImageAttributes = (images: TeaserImage | null, priority: boolean, aspectRatio: string) => {
  if (!images) {
    return images;
  }
  const loading: 'lazy' | 'eager' = priority ? 'lazy' : 'eager';
  const fetchpriority: CustomImgHTMLAttributes['fetchPriority'] = priority ? 'high' : 'low';
  const width = `${Math.round(getNumericRatio(aspectRatio) * 100)}`;
  const height = '100';

  return {
    standardTeaser: { ...images?.standardTeaser, loading, fetchpriority, width, height },
    wobTeaser: { ...images?.wobTeaser, loading, fetchpriority, width, height },
    standardTeaserPrimaryLarge: { ...images?.standardTeaserPrimaryLarge, loading, fetchpriority, width, height },
  };
};
