import { FigureProps } from '@/ts/interfaces';
import Figure from '../Figure/Figure';
import styles from './styles/ImageEmbed.module.scss';
import CameraIcon from '../../../svg/camera';

const ImageEmbed: React.FC<FigureProps> = ({
  dataTestId,
  data: { cameraIconColor, captionCredit, captionTitle, publication, ...rest },
}) => {
  const showCaption = captionTitle || captionCredit;
  const imageLead = dataTestId === 'leadimage';

  return (
    <div className={[styles['image-embed'], imageLead && styles['image-lead']].join(' ')} data-testid={dataTestId}>
      <Figure data={rest} />
      {showCaption && (
        <figcaption className={styles['fig-caption']}>
          <span className={styles['camera-style']}>
            <CameraIcon color={cameraIconColor} />
          </span>
          <span className={styles['caption-title']}>{captionTitle}</span>
          {captionCredit && <span className={styles['caption-credit']}>(Image: {captionCredit})</span>}
        </figcaption>
      )}
    </div>
  );
};
export default ImageEmbed;
