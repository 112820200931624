import CONSTANTS from '@/constants/webAlerts';

const { WEB_ALERT_STORAGE_NAME, PERMISSION, AIRSHIP, WEB_ALERT_PROVIDER_STORAGE_NAME } = CONSTANTS;

const checkScrollPosition = (): boolean => {
  const EXTRA_SCROLL = 200;
  const totalPageHeight = document.documentElement.scrollHeight;
  const heightDifference = totalPageHeight - window.innerHeight;
  if (heightDifference > EXTRA_SCROLL && window.scrollY > EXTRA_SCROLL) {
    return true;
  }
  if (heightDifference <= EXTRA_SCROLL) {
    return true;
  }
  return false;
};

const hasAirshipSubscription = async (): Promise<boolean> => {
  if (window.UA) {
    const sdk = await window.UA;
    return !!sdk.channel?.id;
  }
  return true;
};

const hasExistingLocalStorage = async (publication: string, oneSignalEnabled: boolean): Promise<boolean> => {
  const storageName = `${WEB_ALERT_STORAGE_NAME}:${publication}`;
  const storageItem = localStorage.getItem(storageName);
  const storageObject = storageItem ? JSON.parse(storageItem) : '';
  const { expiry, permission } = storageObject;
  const isGranted = permission === PERMISSION.GRANTED;
  const now = new Date().getTime();

  if (expiry && expiry < now) {
    localStorage.removeItem(storageName);
    return false;
  }

  if (storageItem && isGranted && !oneSignalEnabled) {
    const hasExistingSubscription = await hasAirshipSubscription();
    const browserPermission = 'Notification' in window && Notification.permission;
    if (!hasExistingSubscription || browserPermission === 'denied') {
      localStorage.removeItem(storageName);
      return false;
    }
  }
  return !!storageItem;
};

const hasGrantedCurrentSegment = (publication: string) => {
  const storageName = `${WEB_ALERT_STORAGE_NAME}:${publication}`;
  const storageItem = localStorage.getItem(storageName);
  const storageObject = storageItem ? JSON.parse(storageItem) : '';
  const { permission } = storageObject;
  return permission === PERMISSION.GRANTED;
};

const getPreviousPushProvider = () => {
  const storageItem = localStorage.getItem(WEB_ALERT_PROVIDER_STORAGE_NAME);
  if (storageItem) {
    return storageItem;
  }
  return AIRSHIP;
};

const getPreviousTags = async (currentTag: string): Promise<string[]> => {
  if (window.UA && window.OneSignal) {
    const airshipSdk = await window.UA;
    const existingTags = airshipSdk.channel?.tags.tagMap.get('device');
    if (existingTags) {
      const tagsArray = Array.from(existingTags) as string[];
      const tagsToAdd = tagsArray.filter((tag: string) => tag !== currentTag);
      return tagsToAdd;
    }
    return [];
  }
  return [];
};

export {
  checkScrollPosition,
  hasAirshipSubscription,
  hasExistingLocalStorage,
  hasGrantedCurrentSegment,
  getPreviousPushProvider,
  getPreviousTags,
};
