'use client';

import React, { useState, useEffect } from 'react';
import type { BoxProps, Settings } from '@/ts';
import WhatsAppCommunity from '@/components/molecules/WhatsApp/WhatsApp';
import useFeliz from '@/store/feliz/feliz';
import NewsletterSignup from '../../molecules/NewsletterSignup/NewsletterSignup';

export const shouldShowNCUContent = (settings: Settings) => {
  if (
    settings?.commercial?.isSensitiveArticle === true ||
    settings?.commercial?.axatePaywallArticle === true ||
    settings?.commercial?.isTranslatedArticle === true ||
    settings?.commercial?.registrationWallArticle === true
  )
    return false;
  return true;
};
export const selectProduct = async (
  config: any,
  ncuProduct:
    | {
        type: string;
      }[]
    | null
): Promise<string | null> => {
  const { newsletterSignupEnabled, whatsAppCommunityEnabled, whatsAppCommunity } = config;
  // ncuProduct is sorted in preference order
  // Eg:- [{ type: 'whatsapp' }, { type: 'newsletter' }];
  // if a product appears first in the array, its the first preference and so on
  if (!ncuProduct) return null;
  const enabledProduct = ncuProduct.find((product) => {
    if (product.type === 'newsletter' && newsletterSignupEnabled) {
      return true;
    }
    if (product.type === 'whatsapp' && whatsAppCommunityEnabled && !!whatsAppCommunity) {
      return true;
    }
    return false;
  });
  return enabledProduct ? enabledProduct.type : null;
};
const NCUContentBox: React.FC<BoxProps> = (props: BoxProps) => {
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const { config, articleSettings, newsletterData } = props;
  const { newsletterConfig, publication, publicationName, whatsAppCommunity, consentDate } = config;
  const felizStore = useFeliz();
  useEffect(() => {
    const fetchProduct = async () => {
      const product = await selectProduct(config, felizStore?.state?.client?.ncuProduct || null);
      setSelectedProduct(product);
    };
    fetchProduct();
  }, [config, felizStore]);
  if (!shouldShowNCUContent(articleSettings)) {
    return null;
  }
  if (!selectedProduct) {
    return null;
  }
  switch (selectedProduct) {
    case 'newsletter':
      return (
        <NewsletterSignup
          publication={publication}
          newsletterSubscribeBaseUrl={newsletterConfig.subscribeOpen}
          newsletterAttributes={newsletterData}
          articleID={articleSettings.id}
          consentDate={consentDate}
        />
      );
    case 'whatsapp':
      return (
        <WhatsAppCommunity
          publication={publication}
          publicationName={publicationName}
          whatsAppInArticleCommCode={whatsAppCommunity}
        />
      );
    default:
      return null;
  }
};
export default NCUContentBox;
