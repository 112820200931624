'use client';

import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import { InstagramProps } from '@/ts/interfaces/instagram';
import useConsentDataService from '@/store/consent/consent';
import { isVendorConsented } from '@/store/consent/selectors';
import { isEqual } from 'lodash';
import { initialConsentState } from '@/store/assets/initialStates';
import EmbedPlaceholder from '../HtmlEmbed/EmbedPlaceholder';
import styles from './style/Instagram.module.scss';

const InstagramEmbed: React.FC<InstagramProps> = ({ dataTestId, postUrl, publication }) => {
  const consentStore = useConsentDataService();
  const isConsentInitialized = isEqual(consentStore.state, initialConsentState);
  const consentedInstagram = isVendorConsented('facebook')(consentStore);
  const [renderInstagram, setRenderInstagram] = useState(false);
  const [hasConsentedInstagram, setHasConsentedInstagram] = useState(consentedInstagram);

  useEffect(() => {
    setHasConsentedInstagram(consentedInstagram);
    if (hasConsentedInstagram) setRenderInstagram(true);
  }, [consentedInstagram, consentStore, hasConsentedInstagram]);

  return (
    <>
      {(renderInstagram || hasConsentedInstagram) && (
        <div
          className={styles.wrapper}
          data-testid={dataTestId}
          style={!hasConsentedInstagram ? { display: 'none' } : {}}
        >
          <Script src="//www.instagram.com/embed.js" async={true} />
          <blockquote
            role="blockquote"
            className={`${styles.blockquote} instagram-media`}
            data-instgrm-captioned
            data-instgrm-permalink={postUrl}
          ></blockquote>
        </div>
      )}
      {!hasConsentedInstagram && (
        <div className={styles.wrapper} data-testid={dataTestId}>
          <EmbedPlaceholder publication={publication} hidden={isConsentInitialized} />
        </div>
      )}
    </>
  );
};

export default InstagramEmbed;
