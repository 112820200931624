import { SvgProps } from '@/ts/interfaces/svg';

const ExternalLink: React.FC<SvgProps> = ({ color = `var(--color-sem-icon-primary-default)` }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="external-link-icon"
  >
    <g clipPath="url(#clip0_192_5435)">
      <path
        opacity="0.16"
        d="M2.6665 3.56738H13.3332V12.9007C13.3332 13.6371 12.7362 14.234 11.9998 14.234H3.99984C3.26346 14.234 2.6665 13.6371 2.6665 12.9007V3.56738Z"
        fill="#3D3F40"
      />
      <path
        d="M7.33317 3.56689H2.6665V12.9002C2.6665 13.6366 3.26346 14.2336 3.99984 14.2336H11.9998C12.7362 14.2336 13.3332 13.6366 13.3332 12.9002V9.56689M5.99984 10.9002L13.3332 3.56689M13.3332 3.56689H9.99984M13.3332 3.56689V6.90023"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_192_5435">
        <rect width="16" height="16" fill="white" transform="translate(0 0.900146)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExternalLink;
