import { SvgProps } from '@/ts/interfaces/svg';

const ArrowReadNext: React.FC<SvgProps> = ({ color = 'var(--color-sem-icon-primary-default)' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Read Next Article Icon</title>
    <g clipPath="url(#clip0_10024_1156)">
      <circle opacity="0.16" cx="12" cy="12" r="9" fill={color} />
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 15L14 12L11 9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10024_1156">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowReadNext;
