'use client';

import CopyLinkIcon from '@/components/atoms/Icons/CopyLink';
import { useState } from 'react';
import Notification from '@/components/atoms/Notification/Notification';
import log from '@/helpers/utils/log';
import getDirFileName from '@/helpers/utils/dirPath';
import styles from './styles/CopyButton.module.scss';

const colorCopyLink = `var(--color-sem-cta-fg-primary-default)`;
const backgroundCopyLink = `var(--color-sem-cta-bg-primary-default)`;

interface CopyButtonProps {
  buttonClassName: string[];
  url: string;
  dtmdatatrack?: string;
}

const CopyButton = ({ buttonClassName, dtmdatatrack, url }: CopyButtonProps) => {
  const [showNotification, setShowNotification] = useState(false);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(url as string);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      const fileDirectory = getDirFileName(import.meta.url);
      log().error({ message: `${fileDirectory} Error copying link`, error });
    }
  };

  return (
    <div className={styles['button-container']}>
      <button
        onClick={copyLink}
        title="copy link"
        data-tmdatatrack={dtmdatatrack}
        data-tmdatatrack-name="copy-link"
        className={`${styles.button} ${buttonClassName.map((b) => styles[b]).join(' ')}`}
      >
        <CopyLinkIcon color={colorCopyLink} background={backgroundCopyLink} />
      </button>
      {showNotification && <Notification text="Link Copied"></Notification>}
    </div>
  );
};

export default CopyButton;
