'use client';

import { TrackonomicsLoaderProps } from '@/commercial/types';
import useConsentData from '@/store/consent/consent';
import { isVendorConsented } from '@/store/consent/selectors';
import ScriptLoader from '@/components/atoms/ScriptLoader/ScriptLoader';

const trackonomicsScript = (dataCustomerId: string, dataPropertyId: string) => `
  (function () {
    var s = document.createElement('script'),
      el = document.getElementsByTagName('script')[0];
    s.id = 'funnel-relay-installer';
    s.async = true;
    s.setAttribute('data-property-id', '${dataPropertyId}');
    s.setAttribute('data-customer-id', '${dataCustomerId}');
    s.src = 'https://cdn-magiclinks.trackonomics.net/client/static/v2/${dataCustomerId}.js';
    el.parentNode.insertBefore(s, el);
  })();
`;

const TrackonomicsLoader: React.FC<TrackonomicsLoaderProps> = ({ dataCustomerId, dataPropertyId }) => {
  const consentStore = useConsentData();
  const consented = isVendorConsented('impact')(consentStore); // 'impact.com' is the parent company of 'trackonomics'

  return consented ? (
    <>
      <ScriptLoader testId="trackonomics-script" id="trackonomics-pageload">
        {trackonomicsScript(dataCustomerId, dataPropertyId)}
      </ScriptLoader>
      ;
    </>
  ) : null;
};

export default TrackonomicsLoader;
