'use client';

import { FC } from 'react';
import styles from './styles/TimelineSkeleton.module.scss';

const TimelineSkeletonItem: FC = () => (
  <div className={styles['timeline-skeleton-item']} data-testid="timeline-skeleton-item">
    <div className={styles['timeline-skeleton-item-title']} />
    <div className={styles['timeline-skeleton-item-content']}>
      <div className={styles['timeline-skeleton-item-content-image']} />
      <div className={styles['timeline-skeleton-item-content-text']} />
      <div className={styles['timeline-skeleton-item-content-text']} />
      <div className={styles['timeline-skeleton-item-content-text']} />
    </div>
  </div>
);

const TimelineSkeleton: FC = () => (
  <div className={styles['timeline-skeleton-container']}>
    <div className={styles['timeline-skeleton-header']}>
      <div className={styles['timeline-skeleton-header-title']} />
      <div className={styles['timeline-skeleton-header-line']} />
    </div>
    <div className={styles['timeline-skeleton-line']} />

    {[1, 2, 3].map((_, index) => (
      <TimelineSkeletonItem key={`skeleton-${index}`} />
    ))}
  </div>
);

export default TimelineSkeleton;
