'use client';

import type { BoxProps, Settings } from '@/ts';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import usePage from '@/store/page/page';
import ReadMoreRecommended from '../ReadMoreRecommended/ReadMoreRecommended';

export const shouldShowRecommendedContent = (settings: Settings) => {
  if (
    settings?.advanced?.isEvergreenStory === true ||
    settings?.commercial?.isEvergreenAffiliateArticle === true ||
    settings?.main?.hasActiveLegalProceedings === true ||
    settings?.commercial?.isSensitiveArticle === true
  )
    return false;
  return true;
};

export const DisabledOnCommercialContent = () => {
  const pageData = usePage();
  const pageContentType = pageData?.state?.server?.contentType?.type;
  const pageContentSubType = pageData?.state?.server?.contentType?.subType;
  const isDisabledOnCommercialContent = [
    'affiliate',
    'marketplace',
    'advertising',
    'advertorial',
    'sponsored',
  ].includes(pageContentSubType);

  if ((pageContentType === 'news' || pageContentType === 'opinion') && !isDisabledOnCommercialContent) {
    return true;
  }
  return false;
};

const ReacommendedContentBox: React.FC<BoxProps> = (props: BoxProps) => {
  const { config, articleSettings } = props;
  const { readMoreRecommendedEnabled, growthbookEnabled } = config;
  if (!shouldShowRecommendedContent(articleSettings)) {
    return null;
  }
  if (!DisabledOnCommercialContent()) {
    return null;
  }

  return (
    <ErrorBoundaryFallback>
      {readMoreRecommendedEnabled && (
        <ReadMoreRecommended
          title="READ MORE"
          dataTestId="read-more-recommended"
          dataTmDataTrack="read-more"
          growthbookEnabled={growthbookEnabled}
        />
      )}
    </ErrorBoundaryFallback>
  );
};
export default ReacommendedContentBox;
