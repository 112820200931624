import { AnalyticsData } from '@/ts';
import getDirFileName from '@/helpers/utils/dirPath';
import {
  externalDebug,
  obtainBrowserPermission,
  setWebAlertsLocalStorage,
  setPushProvider,
  urlBase64ToUint8Array,
} from './webAlertUtils';
import CONSTANTS from '../../constants/webAlerts';

const { WEB_ALERT_STORAGE_NAME, PERMISSION, TTL, ONE_SIGNAL, AIRSHIP } = CONSTANTS;

const subscribeToAirship = (
  publication: string,
  url: string,
  clickEvent: Function,
  analytics: AnalyticsData,
  tagName: string,
  segmentScope: string
) => {
  window.UA?.then((sdk: any) => {
    sdk.addEventListener('channel', (e: { channel: { id: any } }) => {
      externalDebug(`airship channel created ${e.channel.id}`);
      setWebAlertsLocalStorage(
        `${WEB_ALERT_STORAGE_NAME}:${publication}${segmentScope}`,
        PERMISSION.GRANTED,
        TTL.ACCEPT
      );
      setPushProvider(AIRSHIP);
      sdk.channel.tags.add(tagName);
      externalDebug(`airship channel tags added: ${tagName}`);
      clickEvent('WEB_ALERTS:WEB_ALERT_SUBSCRIBE_SUCCESS', {
        linkURL: url,
        componentID: '',
        componentText: '',
        ...analytics,
      });
    });
    sdk.register();
  });
};

const subscribeToPushManager = async (vapidPublicKey: string) => {
  const options = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
  };

  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    externalDebug('service worker registered');
    const subscription = await serviceWorkerRegistration.pushManager.getSubscription();

    if (subscription) {
      externalDebug('got previous subscription, unsubscribing');
      await subscription.unsubscribe();
    }
    externalDebug('creating new push manager subscription');
    await serviceWorkerRegistration.pushManager.subscribe(options);
    externalDebug('push manager subscription created');
  } catch (error) {
    console.error(`${getDirFileName(import.meta.url)} Error:`, error);
  }
};

const handlePermissionRequest = async (
  currentPermission: string,
  url: string,
  clickEvent: Function,
  analytics: AnalyticsData
) => {
  if (currentPermission === PERMISSION.DEFAULT) {
    const updatedPermission = await obtainBrowserPermission(url, clickEvent, analytics);
    return updatedPermission === PERMISSION.GRANTED;
  }
  return false;
};

const unsubscribeAirship = async () => {
  if (window.UA) {
    const sdk = await window.UA;
    const channelId = !!sdk.channel?.id;
    if (channelId) {
      await sdk.channel?.optOut();
      externalDebug(`Unsubscribed from Airship`, ONE_SIGNAL);
    }
  }
};

export const webAlertUtils = {
  subscribeToAirship,
  subscribeToPushManager,
  handlePermissionRequest,
  unsubscribeAirship,
};

const handleSubscribeToWebAlerts = async (
  vapidPublicKey: string,
  publication: string,
  url: string,
  clickEvent: Function,
  analytics: AnalyticsData,
  tagName: string,
  segmentScope: string
) => {
  externalDebug('-- subscribing to web alerts --');
  const currentPermission = 'Notification' in window ? Notification.permission : null;

  if (!currentPermission || currentPermission === PERMISSION.DENIED) return;
  const permissionGranted =
    currentPermission === PERMISSION.GRANTED ||
    (await webAlertUtils.handlePermissionRequest(currentPermission, url, clickEvent, analytics));

  if (permissionGranted) {
    try {
      await webAlertUtils.subscribeToPushManager(vapidPublicKey);
      webAlertUtils.subscribeToAirship(publication, url, clickEvent, analytics, tagName, segmentScope);
    } catch (error) {
      clickEvent('WEB_ALERTS:WEB_ALERT_SUBSCRIBE_FAIL', {
        linkURL: url,
        componentID: '',
        componentText: '',
        ...analytics,
      });
    }
  }
};

export const handleSubscribeToWebAlertsOS = async (
  publication: string,
  url: string,
  clickEvent: Function,
  analytics: AnalyticsData,
  tagName: string,
  segmentScope: string,
  isMigratingUser: boolean,
  previousTags: string[],
  isSubscribingToCurrentSegment: boolean
) => {
  externalDebug(`-- ${isMigratingUser ? 'migrating' : 'subscribing'} to OneSignal --`, ONE_SIGNAL);
  const oneSignalSDK = await window.OneSignal;
  if (oneSignalSDK) {
    oneSignalSDK.setConsentGiven(true);
    const subscriptionListener = (event: any) => {
      if (event.current.onesignalId) {
        externalDebug(`OneSignal channel created. ID: ${event.current.onesignalId}`, ONE_SIGNAL);
        setPushProvider(ONE_SIGNAL);

        if (isMigratingUser) {
          const previousTagsObject = {};
          if (previousTags.length) {
            previousTags.forEach((tag) => {
              Object.assign(previousTagsObject, { [tag]: 'true' });
            });
          }
          Object.assign(previousTagsObject, { is_migrated_user: 'true' });
          clickEvent('WEB_ALERTS:WEB_ALERT_MIGRATE_SUCCESS_OS', {
            linkURL: url,
            componentID: '',
            componentText: '',
            ...analytics,
          });
          oneSignalSDK.User?.addTags({
            ...previousTagsObject,
          });
          externalDebug(`OneSignal previous tags added:`, ONE_SIGNAL);
          webAlertUtils.unsubscribeAirship();
        }
        if (isSubscribingToCurrentSegment) {
          oneSignalSDK.User?.addTags({
            [tagName]: 'true',
          });
          setWebAlertsLocalStorage(
            `${WEB_ALERT_STORAGE_NAME}:${publication}${segmentScope}`,
            PERMISSION.GRANTED,
            TTL.ACCEPT
          );
          if (!isMigratingUser) {
            clickEvent('WEB_ALERTS:WEB_ALERT_SUBSCRIBE_SUCCESS_OS', {
              linkURL: url,
              componentID: '',
              componentText: '',
              ...analytics,
            });
          }
          externalDebug(`OneSignal tag added: ${tagName}`, ONE_SIGNAL);
        }
      }
    };

    const currentPermission = 'Notification' in window ? Notification.permission : null;
    if (!currentPermission || currentPermission === PERMISSION.DENIED) return;
    const permissionGranted =
      currentPermission === PERMISSION.GRANTED ||
      (await webAlertUtils.handlePermissionRequest(currentPermission, url, clickEvent, analytics));

    if (permissionGranted) {
      oneSignalSDK.User?.addEventListener('change', subscriptionListener);
      try {
        const hasExistingSubscription = oneSignalSDK.User?.onesignalId;
        if (hasExistingSubscription) {
          oneSignalSDK.User?.addTags({
            [tagName]: 'true',
          });
          setWebAlertsLocalStorage(
            `${WEB_ALERT_STORAGE_NAME}:${publication}${segmentScope}`,
            PERMISSION.GRANTED,
            TTL.ACCEPT
          );
          clickEvent('WEB_ALERTS:WEB_ALERT_SUBSCRIBE_SUCCESS_OS', {
            linkURL: url,
            componentID: '',
            componentText: '',
            ...analytics,
          });
          externalDebug(`OneSignal new tag added: ${tagName}`, ONE_SIGNAL);
        } else {
          oneSignalSDK.Notifications.requestPermission();
        }
      } catch (error) {
        clickEvent('WEB_ALERTS:WEB_ALERT_SUBSCRIBE_FAIL_OS', {
          linkURL: url,
          componentID: '',
          componentText: '',
          ...analytics,
        });
      }
    }
  }
};

export default handleSubscribeToWebAlerts;
