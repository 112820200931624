interface QuizConstantsTypes {
  BADGE_TEXT: string;
  CORRECT: string;
  WRONG: string;
  NEXT_QUESTION: string;
  RESULT_QUIZ: string;
  REPLAY_QUIZ: string;
  THANKS_MESSAGE: string;
  PASS_MESSAGE: string;
  FAIL_MESSAGE: string;
  SCORED_MESSAGE: string;
}

const QuizConstants: Readonly<QuizConstantsTypes> = {
  BADGE_TEXT: 'QUIZ',
  CORRECT: 'Correct.',
  WRONG: 'Wrong.',
  NEXT_QUESTION: 'Next Question',
  RESULT_QUIZ: 'See my results!',
  REPLAY_QUIZ: 'REPLAY QUIZ',
  THANKS_MESSAGE: 'Thanks for taking part in this quiz.',
  PASS_MESSAGE: 'You have passed the quiz.',
  FAIL_MESSAGE: 'You have failed the quiz.',
  SCORED_MESSAGE: 'YOU SCORED',
};

export default QuizConstants;
