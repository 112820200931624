'use client';

import dynamic from 'next/dynamic';
import TimelineSkeleton from './TimelineSkeleton/TimelineSkeleton';

const Timeline = dynamic(() => import('./Timeline'), {
  ssr: false,
  loading: () => <TimelineSkeleton />,
});
export default Timeline;
