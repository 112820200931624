import React from 'react';
import { SvgProps } from '../../../ts/interfaces/svg';

const CrossCircle: React.FC<SvgProps> = ({ color }) => (
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.16" cx="18" cy="18.3335" r="13.5" fill={color} />
    <circle cx="18" cy="18.3335" r="13.5" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 15.3335L15 21.3335" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 15.3335L21 21.3335" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CrossCircle;
