import { SvgProps } from '@/ts/interfaces/svg';

const UnsupportedIcon: React.FC<SvgProps> = ({ background }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <circle opacity="0.16" cx="45" cy="45" r="33.75" fill={background} />
    <path
      d="M21.1351 21.1351C15.0276 27.2427 11.25 35.6802 11.25 45C11.25 63.6396 26.3604 78.75 45 78.75C54.3198 78.75 62.7573 74.9724 68.8649 68.8649M21.1351 21.1351C27.2427 15.0276 35.6802 11.25 45 11.25C63.6396 11.25 78.75 26.3604 78.75 45C78.75 54.3198 74.9724 62.7573 68.8649 68.8649M21.1351 21.1351L68.8649 68.8649"
      stroke={background}
      strokeWidth="7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UnsupportedIcon;
