import { Fragment } from 'react';
import ImageEmbed from '@/components/molecules/ImageEmbed/ImageEmbed';
import Teaser from '@/components/organisms/Teaser';
import YoutubeEmbed from '@/components/molecules/YoutubeEmbed/YoutubeEmbed';
import { ProcessedAgnosticData, TransformedMediaData, ImageData, TransformedConfigData, TeaserData } from '@/ts';
import publicFolder from '@/helpers/utils/publicFolder';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import { getAspectRatio, getImageString, isString } from '.';
import { transformTeaserImage } from '../../teaser-helpers';

export function DisplayData(agnosticData: ProcessedAgnosticData): React.ReactNode {
  if (isString(agnosticData)) {
    return <>{agnosticData}</>;
  }

  if (isString(agnosticData.data)) {
    return <>{agnosticData.data}</>;
  }

  return (
    <>
      {agnosticData.data &&
        Array.isArray(agnosticData.data) &&
        agnosticData.data.map((child, i) => <Fragment key={i}>{child.content}</Fragment>)}
    </>
  );
}

export function DisplayGridData(
  gridTitle: string,
  agnosticData: any,
  imageCrops: any,
  imageUrl: string,
  domain: string | undefined,
  publication?: string
): React.ReactNode {
  const getTeaserData = (data: any): TeaserData => ({
    id: data.id,
    headline: data.linkText,
    url: data.linkUrl,
    images: transformTeaserImage(imageUrl, data.image, imageCrops),
    teaserLabelData: {
      url: data.publications[0]?.sections[0]?.url || '',
    },
    hasVideo: data.hasVideo,
    domain,
    componentTitle: gridTitle,
    contentType: data.contentType,
  });

  return (
    <>
      {agnosticData.map((item: any, key: any) => {
        // AF will remove the title field (and has done for grids)
        // but currently `transformImage` in src/helpers/agnosticTransformer/sections/index.ts as used by Teasers
        // expects it, this code adds in a temp title field, this can be removed once transformImage is reworked
        const { image } = item;
        if (image?.file) {
          image.title = image.file;
        }

        return (
          <Teaser
            key={key}
            data={getTeaserData(item)}
            teaserType="primary"
            aspectRatio="3 / 2"
            teaserSize="small"
            index={key + 1}
            placeholderImageUrl={`/${publicFolder}/placeholder-image.svg`}
            scaleImageWidth="100%"
            publication={publication}
            enableAnalytics={true}
            eventName="GRID"
            AnalyticData={{
              componentTitle: gridTitle,
              componentID: item.id,
              componentText: item.linkText,
              componentAction: `grid click ${key + 1}`,
            }}
          />
        );
      })}
    </>
  );
}

export function DisplayMediaData(
  agnosticData: TransformedMediaData,
  configData: TransformedConfigData
): React.ReactNode {
  if (!agnosticData) return null;
  if (agnosticData.name === 'video') {
    const {
      caption,
      videoId,
      childIndex,
      thumbnailURL = '',
      datePublished,
      contentVertical,
      uploadedBy,
      clearance,
      brandSafety,
    } = agnosticData;
    const {
      isSensitiveArticle,
      hasActiveLegalProceedings,
      playerIdNoRec = 'va1g8e9S',
      playerIdRec = 'oCWPX6jA',
      apexDomain,
      isPoster,
      initVideoBeforeLightningLoad = false,
    } = configData;
    const shouldNoRec = hasActiveLegalProceedings || isSensitiveArticle;
    const playerId = shouldNoRec ? playerIdNoRec : playerIdRec;

    const jwPlayerData = {
      videoHeadline: caption,
      videoId,
      childIndex,
      thumbnailURL: thumbnailURL ?? '',
      datePublished,
      contentVertical,
      uploadedBy,
      clearance,
      brandSafety,
      apexDomain: apexDomain ?? 'curiously.co.uk',
      isLeadVideo: isPoster ?? false,
      playerId,
      initVideoBeforeLightningLoad,
    };

    return agnosticData.videoId && <CommercialBox location={'video'} position={1} videoData={jwPlayerData} />;
  }

  const { fileName, imageId, data, publication } = agnosticData;
  const { isPoster, imageUrl, leadImage } = configData;
  const crop = data?.crop;
  if (!fileName) {
    return '';
  }

  const displayData = {
    ...(data?.alt && { alt: data.alt }),
    sizes: leadImage.sizes.mediaQuery,
    ...(crop === 'default'
      ? {
          srcSet: `${getImageString(fileName, imageId, imageUrl, leadImage.crops?.small)} ${
            leadImage.sizes?.small
          }, ${getImageString(fileName, imageId, imageUrl, leadImage.crops?.medium)} ${leadImage.sizes?.medium},
          ${getImageString(fileName, imageId, imageUrl, leadImage.crops?.large)} ${leadImage.sizes?.large}`,
          src: `${getImageString(fileName, imageId, imageUrl, leadImage.crops?.medium)}`,
        }
      : { src: getImageString(fileName, imageId, imageUrl, crop) }),
  };

  const imageLoading = isPoster ? 'eager' : 'lazy';
  const imageFetchpriority = isPoster ? 'high' : 'low';

  if (data) {
    const aspectRatio = getAspectRatio(data.aspect, isPoster);
    data.aspect = aspectRatio;
  }

  return (
    <ImageEmbed
      dataTestId={(isPoster && 'leadimage') || `imageembed-${agnosticData?.imageId}`}
      data={
        {
          ...data,
          ...displayData,
          publication,
          loading: imageLoading,
          fetchpriority: imageFetchpriority,
          isPoster,
        } as ImageData
      }
    />
  );
}

export function DisplayYoutubeData(youtubeData: TransformedMediaData, publication: string): React.ReactNode {
  return <YoutubeEmbed caption={youtubeData.caption} videoId={youtubeData.videoId} publication={publication} />;
}
