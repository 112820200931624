'use client';

import { AffiliateContentSectionProps } from '@/ts/interfaces/affiliateContent';
import { getAffiliateContentHeaderFont } from '@/helpers/utils/fonts';
import styles from './styles/AffiliateContent.module.scss';

const AffiliateContentSection: React.FC<AffiliateContentSectionProps> = ({
  className,
  displayHeading = true,
  heading,
  isOpened = false,
  isCollapsible = true,
  children,
}) => {
  const collapsedClassName = styles.collapse;
  const toggleSection = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const evtTarget = evt?.target as Element;
    evtTarget?.classList?.toggle(collapsedClassName);
  };

  const collapsibleHeaderStyle = isCollapsible
    ? `${styles['collapsible-header']}${isOpened ? '' : ` ${collapsedClassName}`}`
    : '';

  const sectionContainderId = heading && heading.toLowerCase().replaceAll(' ', '-');

  return (
    <div className={`${styles['section-container']}${className ? ` ${className}` : ''}`}>
      {displayHeading &&
        heading &&
        (isCollapsible ? (
          <button
            className={`${styles['section-header']} ${getAffiliateContentHeaderFont()} ${collapsibleHeaderStyle}`}
            onClick={toggleSection}
            aria-label={`expands ${heading}`}
            aria-expanded={isOpened}
            aria-controls={sectionContainderId}
          >
            {heading}
          </button>
        ) : (
          <div className={`${styles['section-header']} ${getAffiliateContentHeaderFont()}`}>{heading}</div>
        ))}
      <div id={sectionContainderId} className={styles['section-content']}>
        {children}
      </div>
    </div>
  );
};
export default AffiliateContentSection;
