'use client';

import dynamic from 'next/dynamic';

export const CuriouslySquareIcon = dynamic(() => import('./curiously/SquareLogo'));
export const LiverpoolEchoSquareIcon = dynamic(() => import('./liverpoolecho/SquareLogo'));
export const MENSquareIcon = dynamic(() => import('./men/SquareLogo'));
export const DailyRecordSquareIcon = dynamic(() => import('./dailyrecord/SquareLogo'));
export const DailyStarSquareIcon = dynamic(() => import('./dailystar/SquareLogo'));
export const BirminghamMailSquareIcon = dynamic(() => import('./birminghammail/SquareLogo'));
export const MirrorSquareIcon = dynamic(() => import('./mirror/SquareLogo'));
export const BristolPostSquareIcon = dynamic(() => import('./bristolpost/SquareLogo'));
export const AllOutFightingSquareIcon = dynamic(() => import('./alloutfighting/SquareLogo'));
export const TheMirrorSquareIcon = dynamic(() => import('./themirror/SquareLogo'));
export const WalesOnlineSquareIcon = dynamic(() => import('./walesonline/SquareLogo'));
export const IrishStarSquareIcon = dynamic(() => import('./irishstar/SquareLogo'));
export const AllOutGamingSquareIcon = dynamic(() => import('./alloutgaming/SquareLogo'));
