'use client';

import { useRef } from 'react';
import { MantisData } from '@/commercial/types';
import useMantis from '../../store/mantis/mantis';

function MantisStoreInitializer({ mantisData }: { mantisData: MantisData }) {
  const initialized = useRef(false);
  if (!initialized.current) {
    useMantis.getState().actions.addMantisConfigToServerStore(mantisData);
  }

  return null;
}

export default MantisStoreInitializer;
