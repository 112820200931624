import styles from './styles/TextInput.module.scss';

export interface TextInputProps extends React.PropsWithChildren {
  type: 'text' | 'email' | 'password';
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  errorMessage?: string;
  isFullWidth?: boolean;
}

const TextInput: React.FC<
  TextInputProps & {
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
> = ({ type, placeholder, className, value, onChange, isValid = true, errorMessage = '', isFullWidth = false }) => (
  <div
    className={`${styles['input-wrapper']} ${className} ${!isValid ? 'invalid' : ''} ${
      isFullWidth ? 'full-width' : ''
    }`}
  >
    <input
      type={type}
      placeholder={placeholder}
      className={`${styles['input-field']} ${className} ${!isValid ? 'invalid' : ''}`}
      value={value}
      onChange={onChange}
    />
    {!isValid && errorMessage && <div className={styles.errmsg}>{errorMessage}</div>}
  </div>
);

export default TextInput;
