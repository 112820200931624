import { useRef, useEffect } from 'react';
import Logo from '@/components/molecules/Logos/Square';
import getDirFileName from '@/helpers/utils/dirPath';
import CTA from '@/components/atoms/CTA/CTA';
import { WhatsAppCommunityProps } from '@/ts/interfaces/whatsapp';
import styles from './style/WhatsApp.module.scss';

const WhatsAppCommunity: React.FC<WhatsAppCommunityProps> = ({
  publication,
  publicationName,
  whatsAppInArticleCommCode,
}) => {
  const privacyPolicyLink = useRef('');

  useEffect(() => {
    const { protocol, hostname } = window.location;
    privacyPolicyLink.current = `${protocol}//${hostname}/privacy-notice/`;
  }, []);

  const leadingText = `Get the latest ${
    publicationName === 'Manchester Evening News' ? 'MEN' : publicationName
  } breaking news on WhatsApp`;

  const targetLink = `https://chat.whatsapp.com/${whatsAppInArticleCommCode}`;

  const handleJoinClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default link behavior to control the redirect
    try {
      window.feliz.event('whatsapp-click', { publication, publicationName, targetLink });
    } catch (error) {
      const fileDirectory = getDirFileName(import.meta.url);
      console.error(`${fileDirectory} Failed to log event with window.feliz:`, error);
    }
    window.location.href = targetLink; // Redirect to the WhatsApp link after firing the event
  };

  return (
    <div
      id="whatsapp-community"
      className={styles.wrapper}
      data-tmdatatrack="inline-engagement-ncu"
      data-tmdatatrack-subtype="whatsapp"
      data-testid="whatsapp-community"
    >
      <div id="whatsapp-community" role="region" aria-label={leadingText}>
        <div className={styles['logo-and-text']}>
          <div className={styles['publication-logo']}>
            <Logo publication={publication} size="small" />
          </div>
          <div className={styles['leading-text']}>
            <h6>{leadingText}</h6>
          </div>
        </div>
        <div className={styles['paragraph-text']}>
          <p>
            Our community members are treated to special offers, promotions and adverts from us and our partners. You
            can check out at any time. <a href={privacyPolicyLink.current || '/privacy-notice'}>More info</a>
          </p>
        </div>
        <div className={styles['whatsapp-button-wrapper']}>
          <CTA
            design="social"
            social="whatsapp"
            isLink
            label="Join us on WhatsApp"
            href={targetLink}
            onClick={handleJoinClick}
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppCommunity;
