import clientWidth from '@/helpers/utils/clientWidth';

function getColumns(tilesNumber: number): number {
  const breakpoint = clientWidth();

  switch (breakpoint) {
    case 'small':
      return tilesNumber === 1 ? 2 : 0;
    case 'medium':
    case 'xlarge':
      return tilesNumber === 1 ? 2 : tilesNumber;
    case 'large':
      return tilesNumber < 4 && tilesNumber !== 1 ? tilesNumber : 2;
    default:
      return 0;
  }
}

export default getColumns;
