'use client';

import { transformRhsRecommended } from '@/helpers/agnosticTransformer/article/utilities';
import useFeliz from '@/store/feliz/feliz';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { RecirculationAgnostic } from '@/ts/interfaces/agnostic';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useRef, useState } from 'react';
import clientWidth from '@/helpers/utils/clientWidth';
import usePage from '@/store/page/page';
import ExternalLink from '@/components/atoms/Icons/ExternalLink';
import Image from '@/components/atoms/Image/Image';
import styles from './styles/TopSlotContainer.module.scss';

interface TopSlotProps {
  experimentVariant: string;
  publication?: string;
  showBox?: boolean;
  rightHandRail?: any;
  indexStart: number;
  indexEnd?: number;
  isMock?: boolean;
  mockData?: RecirculationAgnostic[];
  shopWindowConfig?: Record<string, any>;
}

export const DisabledOnCommercialContent = (pageData: any) => {
  const pageContentType = pageData?.state?.server?.contentType?.type;
  const pageContentSubType = pageData?.state?.server?.contentType?.subType;
  const isSensitiveArticle = pageData?.state?.server?.isSensitiveArticle;
  const hasActiveLegalProceedings = pageData?.state?.server?.hasActiveLegalProceedings;

  const isDisabledOnCommercialContent = [
    'affiliate',
    'marketplace',
    'advertising',
    'advertorial',
    'sponsored',
  ].includes(pageContentSubType);

  if (
    (pageContentType === 'news' || pageContentType === 'opinion') &&
    !isDisabledOnCommercialContent &&
    isSensitiveArticle !== true &&
    hasActiveLegalProceedings !== true
  ) {
    return true;
  }
  return false;
};

export const convertDataFormat = (lists: any[], type: string) =>
  lists
    .filter(({ seoUrl, title, teaserTitle }) => seoUrl && title && teaserTitle)
    .map(({ id, title, seoUrl, publicationName, subType, teaserId, teaserTitle }) => {
      const getBaseUrlWithSection = (url: string): string => {
        const parsedUrl = new URL(url);
        const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
        pathSegments.pop();
        return `${parsedUrl.origin}/${pathSegments.join('/')}`;
      };

      return {
        id,
        headline: title,
        url: seoUrl,
        platform: publicationName,
        source: subType === 'affiliate' ? 'AFFILIATE_SHOP_WINDOW' : 'SHOP_WINDOW',
        image: { src: `${getBaseUrlWithSection(seoUrl)}/article${teaserId}.ece/ALTERNATES/s458/${teaserTitle}` },
        hostName: new URL(seoUrl)?.hostname,
        type,
      };
    });

const TopSlotBox: React.FC<TopSlotProps> = ({ indexStart, isMock, mockData, shopWindowConfig, experimentVariant }) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [updatedTeasers, setUpdatedTeasers] = useState<any[]>([]);

  const breakpoint = clientWidth();
  const baseURL = shopWindowConfig?.article || '';
  const pageData = usePage();
  const felizStore = useFeliz();

  const isVariantTwo = experimentVariant === '2';

  const rhsRecommended = felizStore.state.client.recirculation?.rhs?.recommended ?? [];
  const teasersData = isMock ? (mockData as RecirculationAgnostic[]) : transformRhsRecommended(rhsRecommended);
  const indexEnd = isVariantTwo ? 3 : 5;
  const shopWindowLoaded = useRef<boolean>(false);

  useEffect(() => {
    setIsDesktop(['large', 'xlarge', 'xxlarge'].includes(breakpoint));
  }, [breakpoint]);

  const fetchData = (requestUrl: string) =>
    fetch(requestUrl, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('[Error: HTTP error! Status: 500]');
        }
        return response.json();
      })
      .catch((err) => {
        console.error(`StringContaining, ${err}`);
      });

  useEffect(() => {
    const fetchTopSlotData = async () => {
      if (teasersData.length > 0 && !shopWindowLoaded.current) {
        const updateTeasers = teasersData;
        try {
          const shopWindowRes = await fetchData(`${baseURL}?publicationName=mirror`);
          const affiliatesRes = await fetchData(
            `${baseURL}?publicationName=mirror&groupName=affiliateShopWindowTopslot`
          );

          shopWindowLoaded.current = true;

          if (shopWindowRes.length > 0) updateTeasers.splice(0, 0, convertDataFormat(shopWindowRes, 'topSlot')[0]);
          if (affiliatesRes.length > 0)
            updateTeasers.splice(isVariantTwo ? 2 : 3, 0, convertDataFormat(affiliatesRes, 'topSlotAffiliates')[0]);
        } catch (err) {
          console.error(`StringContaining, ${err}`);
        }
        setUpdatedTeasers(updateTeasers);
      }
    };

    fetchTopSlotData();
  }, [teasersData]);

  const mainClasses = `${styles.teaser} ${isVariantTwo ? styles[`teaser-secondary`] : styles[`teaser-primary`]}`;

  const affiliatePlatforms = ['mirror'];

  if (!DisabledOnCommercialContent(pageData)) return null;

  return (
    <ErrorBoundaryFallback>
      {isDesktop && (
        <div className={styles['top-slot-container']} data-testid={'topbox-container'}>
          <div
            className={styles['recommended-wrapper']}
            style={{ gridTemplateColumns: `repeat(${isVariantTwo ? 3 : 5}, 1fr)` }}
          >
            {updatedTeasers.length > 0
              ? updatedTeasers.slice(indexStart, indexEnd).map((item, index) => {
                  const image = item.image || item.images?.standardTeaser;
                  const advertisingFeature = item.source === 'SHOP_WINDOW' ? 'AD FEATURE' : null;
                  return (
                    <a
                      className={mainClasses}
                      href={item.url}
                      key={index}
                      aria-label={item.headline}
                      data-Tmdatatrack={'topbox'}
                      data-tmdatatrack-index={index}
                      data-tmdatatrack-source={item.source}
                      data-tmdatatrack-platform={item.platform}
                      data-tmdatatrack-articleid={item.id}
                      data-testid={'topbox-teaser'}
                    >
                      <div className={`${styles['cover-wrapper']} `}>
                        <Image
                          src={image?.src}
                          alt={image?.altText || item?.headline}
                          sizes={image?.sizes}
                          srcSet={image?.srcSet}
                          fetchPriority="auto"
                        />
                        {advertisingFeature && (
                          <span className={styles['advertisement-label']}>
                            <em>{advertisingFeature}</em>
                          </span>
                        )}
                      </div>
                      <div>
                        <h2 className={styles.heading}>{item.headline}</h2>
                        {affiliatePlatforms.includes(item.platform) && (
                          <div className={styles['additional-content']}>
                            <a href={item.url}>
                              {' '}
                              <ExternalLink /> <span>{item?.hostName?.replace('www.', '')}</span>{' '}
                            </a>
                          </div>
                        )}
                      </div>
                    </a>
                  );
                })
              : Array.from({ length: indexEnd - indexStart }).map((_, index) => (
                  <div
                    className={styles['skeletton-item']}
                    key={index}
                    style={{ gridTemplateColumns: `repeat(${isVariantTwo ? 2 : 1}, 1fr)` }}
                    data-testid="topslot-skeletton-item"
                  >
                    <div className={styles['image-wrapper']}>
                      <Skeleton height="95px" />
                    </div>
                    <div className={styles['teaser-link']}>
                      <Skeleton count={4} />
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}
    </ErrorBoundaryFallback>
  );
};

export default TopSlotBox;
