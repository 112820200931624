import { AffiliateContentRatingsProps } from '@/ts/interfaces/affiliateContent';
import Icon from '@/components/atoms/Icon/Icon';
import styles from './styles/AffiliateContentRatings.module.scss';

const AffiliateContentRatings: React.FC<AffiliateContentRatingsProps> = ({ rating, bestRating }) => {
  function getRatings() {
    const ratingVal = Number(rating);
    return [...Array(Number(bestRating))].map((val, idx) => {
      const isActive = idx + 1 <= ratingVal;
      return (
        <span key={`star-icon-${idx}`} className={`${styles['star-icon']}${isActive ? ` ${styles.active}` : ''}`}>
          <Icon name="star" />
        </span>
      );
    });
  }

  return <div className={styles['content-rating']}>{getRatings()}</div>;
};
export default AffiliateContentRatings;
