import React from 'react';
import Check from '../Icons/Check';
import styles from './styles/Notification.module.scss';

export interface NotificationProps extends React.PropsWithChildren {
  text: string;
}

const color = `var(--color-sem-icon-primary-default)`;

const Notification: React.FC<NotificationProps> = ({ text }) => (
  <div className={styles.notification}>
    <label>{text}</label>
    <Check color={color} />
  </div>
);
export default Notification;
