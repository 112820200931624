import { SvgProps } from '@/ts/interfaces/svg';

const Icon: React.FC<SvgProps> = ({ color, background }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.0005" y="0.0005" width="39.999" height="39.999" rx="19.9995" fill={background} />
    <rect x="0.0005" y="0.0005" width="39.999" height="39.999" rx="19.9995" stroke={color} strokeWidth="0.001" />
    <g clipPath="url(#clip0_2052_10293)">
      <path d="M16 20H24" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17 16H14C11.7909 16 10 17.7909 10 20C10 22.2091 11.7909 24 14 24H17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 16H26C28.2091 16 30 17.7909 30 20C30 22.2091 28.2091 24 26 24H23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2052_10293">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
