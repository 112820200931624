interface PublicationConstants {
  CURIOUSLY: string;
  LIVERPOOL_ECHO: string;
  MEN: string;
  DAILY_RECORD: string;
  DAILY_STAR: string;
  BIRMINGHAM_MAIL: string;
  MIRROR: string;
  BRISTOL_POST: string;
  ALLOUTFIGHTING: string;
  THE_MIRROR: string;
  WALES_ONLINE: string;
  IRISH_STAR: string;
  ALLOUTGAMING: string;
}

const PUBLICATIONS: Readonly<PublicationConstants> = {
  CURIOUSLY: 'curiously',
  LIVERPOOL_ECHO: 'liverpoolecho',
  MEN: 'men',
  DAILY_RECORD: 'dailyrecord',
  DAILY_STAR: 'dailystar',
  BIRMINGHAM_MAIL: 'birminghammail',
  MIRROR: 'mirror',
  BRISTOL_POST: 'bristolpost',
  ALLOUTFIGHTING: 'alloutfighting',
  THE_MIRROR: 'themirror',
  WALES_ONLINE: 'walesonline',
  IRISH_STAR: 'irishstar',
  ALLOUTGAMING: 'alloutgaming',
};

export default PUBLICATIONS;
