import log from '@/helpers/utils/log';
import getDirFileName from './dirPath';

const getNumericRatio = (ratio: string): number => {
  try {
    return Number(ratio.split('/').reduce((prev, curr) => (Number(prev) / Number(curr)).toString()));
  } catch (e) {
    log().debug(`${getDirFileName(import.meta.url, getNumericRatio.name)} Invalid aspect ratio ${ratio}`);
    return 1;
  }
};

export default getNumericRatio;
