import { SvgProps } from '../../../ts/interfaces/svg';

const CorrectIcon: React.FC<SvgProps> = ({ color }) => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 1.3335L6 11.3335L1 6.3335"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CorrectIcon;
