'use client';

import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import styles from './styles/Tags.module.scss';

export interface TagsProps extends React.PropsWithChildren {
  linkUrl: string;
  linkText: string;
  dataTmDataTrack?: string;
  dataTrackName?: string;
}

const Tags: React.FC<TagsProps> = ({ linkUrl, linkText, dataTmDataTrack, dataTrackName }) => (
  <AnalyticsTrack
    analyticsData={linkHelper('CONTENT_TAGS', linkUrl, {
      componentTitle: dataTmDataTrack,
      componentID: '',
      componentText: linkText,
    })}
  >
    <a className={styles.tags} href={linkUrl} data-tmdatatrack-name={dataTrackName} data-tmdatatrack={dataTmDataTrack}>
      {linkText}
    </a>
  </AnalyticsTrack>
);

export default Tags;
