'use client';

import React, { useState } from 'react';
import Questionnaire from '@/components/organisms/StandardQuiz/Questionnaire/Questionnaire';

import { QuizEmbedProps } from '@/ts/interfaces/standardQuiz';
import Badge from '@/components/molecules/Badge/Badge';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import { TransformedQuizOption } from '@/ts';
import CTA from '@/components/atoms/CTA/CTA';
import QuizResult from './QuizResult/QuizResult';
import QuizConstants from '../../../constants/quiz';
import styles from './styles/StandardQuiz.module.scss';

const QuizEmbed: React.FC<QuizEmbedProps> = ({ dataTmDataTrack, questions, scoreRemarks, title, id }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [score, setScore] = useState(0);
  const [quizComplete, setQuizComplete] = useState(false);
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setIsNextButtonDisabled(true);
    setShowQuizResult(false);
    setScore(0);
    setQuizComplete(false);
    clickEvent('QUIZ:REPLAY', { componentTitle: title, componentID: id, linkURL: window.location.href, ...analytics });
  };

  const isNextQuestion = () => {
    if (questions && currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setIsNextButtonDisabled(true);
    } else {
      setShowQuizResult(true);
      setQuizComplete(true);
      clickEvent('QUIZ:COMPLETED', {
        componentTitle: title,
        componentID: id,
        linkURL: window.location.href,
        ...analytics,
      });
    }
  };

  const handleOptionSelected = (isCorrect: boolean) => {
    setIsNextButtonDisabled(false);

    // Update the score if the selected answer is correct
    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }
    if (currentQuestionIndex === 0) {
      clickEvent('QUIZ:STARTED', {
        componentTitle: title,
        componentID: id,
        linkURL: window.location.href,
        ...analytics,
      });
    }
  };

  let buttonText;

  if (quizComplete) {
    buttonText = QuizConstants.REPLAY_QUIZ;
  } else if (questions && currentQuestionIndex < questions.length - 1) {
    buttonText = QuizConstants.NEXT_QUESTION;
  } else {
    buttonText = QuizConstants.RESULT_QUIZ;
  }

  function getAnswerImage(options: TransformedQuizOption[]) {
    const correctAnswerImage = options.find((option) => option.correctAnswer === true)?.imageData;
    return correctAnswerImage;
  }

  return (
    <div
      className={styles['quiz-container']}
      data-tmdatatrack={dataTmDataTrack}
      data-tmdatatrack-articleid={id}
      data-testid="quiz"
    >
      <div className={styles['top-heading']}>
        <Badge linkText={QuizConstants.BADGE_TEXT}></Badge>
        <div className={styles['progress-item']}>
          <div className={styles['question-progress']}>
            Question -{' '}
            <span>
              {currentQuestionIndex + 1} of {questions ? questions.length : 0}
            </span>
          </div>
          <div className={styles['score-progress']}>
            Score -{' '}
            <span>
              {score} of {questions && questions.length}
            </span>
          </div>
        </div>
      </div>
      <div className={styles['content-section-wrapper']}>
        {!showQuizResult && !quizComplete && (
          <Questionnaire
            headingText={questions && questions[currentQuestionIndex].text}
            options={questions && questions[currentQuestionIndex].options}
            resultText={questions && questions[currentQuestionIndex].resultText}
            onOptionSelected={(isCorrect: any) => handleOptionSelected(isCorrect)}
            answerImage={getAnswerImage(questions && questions[currentQuestionIndex].options)}
            questionImage={questions && questions[currentQuestionIndex].questionImageData}
          />
        )}
        {showQuizResult && <QuizResult score={`${score}/${questions.length}`} scoreRemarks={scoreRemarks} />}
        <div className={`${styles['button-wrapper']} ${quizComplete && styles['button-wrapper-quiz-complete']}`}>
          <CTA
            label={buttonText}
            ariaLabel={buttonText}
            title={quizComplete ? QuizConstants.REPLAY_QUIZ : QuizConstants.NEXT_QUESTION}
            onClick={quizComplete ? resetQuiz : isNextQuestion}
            disabled={isNextButtonDisabled}
            design="primary"
            icons={quizComplete ? { icon: 'replay' } : { secondaryIcon: 'arrow_right_2' }}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizEmbed;
