import { SvgProps } from '../../../ts/interfaces/svg';

const WrongIcon: React.FC<SvgProps> = ({ color }) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1.3335L11 11.3335M1 11.3335L11 1.3335"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WrongIcon;
