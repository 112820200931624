'use client';

import useConsentData from '@/store/consent/consent';
import { isVendorConsented } from '@/store/consent/selectors';
import ScriptLoader from '../ScriptLoader/ScriptLoader';

const LinkbyPubFeed: React.FC<{ enabled: boolean; url: string }> = ({ enabled, url }) => {
  const consentStore = useConsentData();
  const consented = isVendorConsented('linkby')(consentStore);

  const LinkbyScript = () => `
    (function () {
      var s = document.createElement('script'),
        el = document.getElementsByTagName('script')[0];
      s.async = true;
      s.src = '${url}';
      el.parentNode.insertBefore(s, el);
    })()
  `;

  return enabled && consented ? (
    <>
      <ScriptLoader testId="linkby-script" id="linkby-script">
        {LinkbyScript()}
      </ScriptLoader>
      <div className="linkby-widget" data-type="listicle"></div>
    </>
  ) : null;
};
export default LinkbyPubFeed;
