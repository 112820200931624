import CONSTANTS from '@/constants/webAlerts';
import { AnalyticsData } from '@/ts';

interface MessageObject {
  message: string;
  expiry: number;
}

const externalDebug = (message: string, itemName: string = 'airship') => {
  const debugArr = JSON.parse(localStorage.getItem(itemName) || '[]') as MessageObject[];
  const now = new Date().getTime();
  const updatedArray = debugArr.filter((item) => item.expiry > now);

  if (message.length) {
    const currentDate = new Date();
    const expiry = new Date(currentDate);
    expiry.setDate(currentDate.getDate() + 3);
    const messageObj = {
      message,
      expiry: expiry.getTime(),
    };
    updatedArray.push(messageObj);
  }
  localStorage.setItem(itemName, JSON.stringify(updatedArray));
};

const setWebAlertsLocalStorage = (localStorageName: string, permission: string, days: number) => {
  const currentDate = new Date();
  const expiry = new Date(currentDate);
  expiry.setDate(currentDate.getDate() + days);
  const localStorageItem = {
    permission,
    expiry: expiry.getTime(),
  };
  localStorage.setItem(localStorageName, JSON.stringify(localStorageItem));
};

const setPushProvider = (pushProvider: string) => {
  localStorage.setItem(CONSTANTS.WEB_ALERT_PROVIDER_STORAGE_NAME, pushProvider);
};

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = Buffer.from(base64, 'base64');
  const outputArray = new Uint8Array(rawData);
  return outputArray;
};

const obtainBrowserPermission = async (url: string, clickEvent: Function, analytics: AnalyticsData) => {
  const confirmedPermission = await Notification.requestPermission();
  clickEvent('WEB_ALERTS:BROWSER_PROMPT_DISPLAYED', { linkURL: url, componentID: '', componentText: '', ...analytics });

  if (confirmedPermission === CONSTANTS.PERMISSION.GRANTED) {
    clickEvent('WEB_ALERTS:BROWSER_PROMPT_ALLOWED', { linkURL: url, componentID: '', componentText: '', ...analytics });
    window.feliz.click('web-alert-notification', 0, 0, {});
  }
  if (confirmedPermission === CONSTANTS.PERMISSION.DENIED) {
    clickEvent('WEB_ALERTS:BROWSER_PROMPT_BLOCKED', { linkURL: url, componentID: '', componentText: '', ...analytics });
    window.feliz.click('web-alert-notification-close', 0, 0, {});
  }

  return confirmedPermission;
};

export { externalDebug, urlBase64ToUint8Array, obtainBrowserPermission, setWebAlertsLocalStorage, setPushProvider };
