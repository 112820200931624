import Icon from '../Icon/Icon';
import styles from './styles/AffiliateDisclaimer.module.scss';

const AffiliateDisclaimer = ({ showIcon = false }: { showIcon?: boolean }) => (
  <div className={styles['affiliate-disclaimer']}>
    {showIcon && (
      <div className={styles['info-icon']}>
        <Icon name="information_circle" />
      </div>
    )}
    <div className={styles['affiliate-text']}>
      This article contains affiliate links, we will receive a commission on any sales we generate from it.{' '}
      <a href="/affiliates">Learn more</a>
    </div>
  </div>
);
export default AffiliateDisclaimer;
