import { isPlainObject as checkObject, has, isString as checkString } from 'lodash';
import { AgnosticInterface, AgnosticData, RecirculationAgnostic, ImageAttributes } from '@/ts';

export function isString(data: unknown): data is string {
  return checkString(data);
}

export function isPlainObject(data: unknown): data is object {
  return checkObject(data);
}

function checkChildren(data: AgnosticInterface): data is AgnosticInterface {
  return has(data, 'children') && data.children.length > 0;
}

export function checkValid(data: AgnosticData): data is AgnosticInterface {
  return !!data && !isString(data) && isPlainObject(data);
}

export function hasChildren(data: AgnosticData): data is AgnosticInterface {
  return checkValid(data) && checkChildren(data);
}

export function hasSingleChildString(data: AgnosticData): data is AgnosticInterface {
  return hasChildren(data) && Array.isArray(data.children) && data.children.length === 1 && isString(data.children[0]);
}

export function getImageString(file: string, id: string, imageUrl: string, crop: string = 's1200e'): string {
  return `${imageUrl}/article${id}.ece/ALTERNATES/${crop}/${file}`;
}

export function getImageSrc(imageAttrs: ImageAttributes, imgBaseUrl: string, crop: string = 's1200e') {
  const { file, id: imageId } = imageAttrs;
  const src = file && imageId ? getImageString(file, imageId, imgBaseUrl, crop) : '';
  return src;
}

/**
 * Returns the appropriate aspect ratio based on the provided parameters.
 */
export function getAspectRatio(aspect: number, isPoster?: boolean): number {
  return isPoster || aspect <= 1 ? 1.5 : aspect;
}

export function getEmbedType(embedType: string | undefined): string | undefined {
  if (!embedType) return undefined;

  let eType = embedType;

  if (embedType.includes('twitter')) {
    eType = 'twitter';
  }

  if (embedType.includes('instagram')) {
    eType = 'instagram';
  }

  if (embedType.includes('tiktok')) {
    eType = 'tiktok';
  }

  if (embedType === 'wayin') {
    eType = 'cheatah';
  }

  return eType;
}

export function getRhsData(data: RecirculationAgnostic) {
  const {
    articleId,
    image: { href },
    title,
    crop,
    url,
    source,
    hasVideo,
    platform,
    contentType,
  } = data;
  const src = href.replace('/BINARY/', `/alternates/${crop?.small}/`);
  const srcsetMedium = href.replace('/BINARY/', `/alternates/${crop?.medium}/`);
  const srcsetLarge = href.replace('/BINARY/', `/alternates/${crop?.large}/`);
  const srcSet = `${src} 458w,${srcsetMedium} 615w, ${srcsetLarge} 1023w`;
  const sizes = `(max-width: 458px)458px ,(max-width:615px)615px, 1023px`;

  return {
    images: {
      standardTeaser: { src, srcSet, loading: 'lazy' as const, fetchpriority: 'low', sizes },
      wobTeaser: { src: '' },
      standardTeaserPrimaryLarge: { src: '' },
    },
    headline: title,
    url,
    id: articleId,
    source,
    teaserLabelData: {
      url,
    },
    hasVideo,
    platform,
    contentType,
  };
}

export function transformRhsRecommended(rhsRecommended: RecirculationAgnostic[]): any[] {
  return rhsRecommended.map((item: RecirculationAgnostic) => {
    const data = { ...item, crop: { small: 's458', medium: 's615', large: 's1023' } };
    return getRhsData(data);
  });
}
